import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { IncidentLog } from 'src/app/models/IncidentLog';
import { AlertService } from 'src/app/services/alert.service';
import { IncidentLogService } from 'src/app/services/incident-log.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-edit-incident-log',
  templateUrl: './edit-incident-log.component.html',
  styleUrls: ['./edit-incident-log.component.scss']
})
export class EditIncidentLogComponent implements OnInit {
  private log: IncidentLog;
  private dataLoaded: boolean = false;
  private viewOnly: boolean = true;
  private editMode: boolean = false;

  constructor(
    private incidentLogService: IncidentLogService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.incidentLogService.getLog(params.id).subscribe((log: IncidentLog) => {
          this.log = log;
          this.dataLoaded = true;
        });
      }
    )
  }

  onClickEdit() {
    this.toggleEditMode();
  }

  onClickDelete() {
    this.alertService.showAreYouSureYouWantToDelete()
      .then((result) => {
        if (result.value) {
          this.incidentLogService.deleteLog(this.log.id).subscribe(() => {
            this.alertService.showDeleteConfirmation("This log has been deleted.");
            this.router.navigate(['/logs/incidents']);
          });
        }
      });
  }

  onClickClose() {
    this.onDone();
  }

  onDoneUpdatingLog(log: IncidentLog) {
    if (this.viewOnly) {
      this.onDone();
      return;
    }
    this.incidentLogService.updateLog(log).subscribe(() => {
      this.onDone();
      this.toastService.showSuccess("Updated successfully");
    });
  }

  onDone() {
    this.router.navigate(['/logs/incidents']);
  }

  toggleEditMode() {
    this.viewOnly = false;
    this.editMode = true;
  }

}
