import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ReportIssue } from 'src/app/models/forms/ReportIssue';

@Component({
  selector: 'app-report-issue-modal',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss']
})
export class ReportIssueComponent implements OnInit {
  @Input() reportIssue: ReportIssue = new ReportIssue();
  @Output() closeEvent = new EventEmitter<any>();
  @Output() doneEvent = new EventEmitter<ReportIssue>();

  constructor() { }

  ngOnInit() {}

  onClickClose() {
    this.closeEvent.emit();
  }

  onClickDone() {
    if (!this.reportIssue.description) {
      this.closeEvent.emit();
    }
    this.doneEvent.emit(this.reportIssue);
  }

}
