import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, TemplateRef, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import Quill from "quill";

import { PartLog } from 'src/app/models/PartLog';
import StringUtils from 'src/app/utils/string-utils';
import DateUtils from 'src/app/utils/date-utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Part } from 'src/app/models/Part';
import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-add-part-log-form',
  templateUrl: './add-part-log.component.html',
  styleUrls: ['./add-part-log.component.scss']
})
export class AddPartLogComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() log: PartLog = new PartLog();
  @Input() viewOnly: boolean = false;
  @Input() editMode: boolean = false;
  @Output() doneEvent = new EventEmitter<PartLog>();
  @ViewChild('addPartLogForm') form: NgForm;
  @ViewChild('cost') costInput: ElementRef;
  private notesTextEditor: Quill;
  private addPartsModal: BsModalRef;
  private uploadFilesModal: BsModalRef;
  private isNotesTextEditorEnabled: boolean = false;
  private selectedPart: Part;

  constructor(
    private fileService: FileService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    if (this.log.notes) {
      this.initNotesTextEditor();
    }
  }

  ngAfterViewInit() {
    if (this.log.purchasedDate) {
      this.log.purchasedDate = StringUtils.formatDateForInput(this.log.purchasedDate);
    }
    if (this.log.cost) {
      this.costInput.nativeElement.value = StringUtils.formatCurrencyWithDefaultDecimal(this.log.cost);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const viewOnlyChange = changes['viewOnly'];
    if (viewOnlyChange) {
      if (viewOnlyChange.currentValue) {
        this.disableNotesTextEditor();
      } else {
        this.enableNotesTextEditor();
      }
    }
  }

  initNotesTextEditor() {
    this.isNotesTextEditorEnabled = true;
    this.notesTextEditor = new Quill("#notesTextEditor", {
      modules: {
        toolbar: [
          ["bold", "italic"],
          [
            {
              list: "ordered"
            },
            {
              list: "bullet"
            }
          ]
        ]
      },
      theme: "snow"
    });
    const delta = this.notesTextEditor.clipboard.convert(this.log.notes);
    this.notesTextEditor.setContents(delta);
    this.setHandlersForNotesTextEditor();
    if (this.viewOnly) {
      this.notesTextEditor.disable();
    }
  }

  setHandlersForNotesTextEditor() {
    this.notesTextEditor.on('text-change', (delta, oldDelta, source) => {
      const textLimit = 1000;
      if (this.notesTextEditor.getLength() > textLimit) {
        this.notesTextEditor.deleteText(textLimit, this.notesTextEditor.getLength());
      }
      this.log.notes = this.notesTextEditor.root.innerHTML;
    });
  }

  enableNotesTextEditor() {
    this.notesTextEditor.enable();
  }

  disableNotesTextEditor() {
    this.notesTextEditor.disable();
  }

  onChangePurchasedDate(element) {
    if (!DateUtils.isValid(this.log.purchasedDate) || !DateUtils.isInPast(moment(this.log.purchasedDate))) {
      element.target.value = null;
    }
  }

  onChangeCost() {
    this.costInput.nativeElement.value = StringUtils.formatCurrencyWithDefaultDecimal(this.costInput.nativeElement.value);
    this.log.cost = StringUtils.formatCurrencyAsNumber(StringUtils.formatCurrencyWithDefaultDecimal(this.costInput.nativeElement.value));
  }

  onClickTodayLink() {
    this.form.controls['purchasedDate'].setValue(moment().format("YYYY-MM-DD"));
  }

  onClickAddPart(modal: TemplateRef<any>) {
    this.selectedPart = new Part();
    this.addPartsModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseAddPartsModal() {
    this.hideAddPartsModal();
  }

  onDoneAddingPart(part: Part) {
    this.log.parts.push(part);
    this.hideAddPartsModal();
  }

  onDoneEditingPart(part: Part) {
    this.hideAddPartsModal();
  }

  hideAddPartsModal() {
    if (this.addPartsModal) {
      this.addPartsModal.hide();
    }
  }

  onClickViewPart(modal: TemplateRef<any>, part: Part) {
    this.selectedPart = part;
    this.addPartsModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickEditPart(modal: TemplateRef<any>, part: Part) {
    this.selectedPart = part;
    this.addPartsModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickDeletePart(part: Part) {
    this.log.parts = this.log.parts.filter(p => p.type !== part.type);
  }

  onClickAddNotesLink() {
    this.initNotesTextEditor();
  }

  onClickAddReceiptLink(modal: TemplateRef<any>) {
    this.uploadFilesModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseUploadFilesModal() {
    this.closeUploadFilesModal();
  }

  onDoneUploadingFiles(files: UploadedFile[]) {
    this.closeUploadFilesModal();
    if (files && files.length > 0) {
      this.log.files.push(...files);
    }
  }

  closeUploadFilesModal() {
    this.uploadFilesModal.hide();
  }

  onClickDeleteFile(file: UploadedFile) {
    this.log.files = this.log.files.filter(f => f.id !== file.id);
    if (!this.editMode) {
      this.fileService.deleteFile(file.id).subscribe(() => {});
    }
  }

  onClickDone() {
    this.onFormSubmit();
    if (!this.isFormValid()) {
      return;
    }
    this.doneEvent.emit(this.log);
  }

  onFormSubmit() {
    this.form.control.markAllAsTouched();
  }

  isFormValid() {
    return this.form.valid;
  }

}
