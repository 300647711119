import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule as AngularFormsModule } from '@angular/forms';

import { ComponentsModule } from '../components/components.module';
import { ModalsModule } from '../modals/modals.module';
import { AddServiceLogComponent } from './add-service-log/add-service-log.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { AddVehicleComponent } from './add-vehicle/add-vehicle.component';
import { AddGasLogComponent } from './add-gas-log/add-gas-log.component';
import { AddRegistrationLogComponent } from './add-registration-log/add-registration-log.component';
import { AddIncidentLogComponent } from './add-incident-log/add-incident-log.component';
import { AddPartLogComponent } from './add-part-log/add-part-log.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    AngularFormsModule,
    ComponentsModule,
    ModalsModule
  ],
  declarations: [
    AddServiceLogComponent,
    UploadFilesComponent,
    AddVehicleComponent,
    AddGasLogComponent,
    AddRegistrationLogComponent,
    AddIncidentLogComponent,
    AddPartLogComponent
  ],
  exports: [
    AddServiceLogComponent,
    UploadFilesComponent,
    AddVehicleComponent,
    AddGasLogComponent,
    AddRegistrationLogComponent,
    AddIncidentLogComponent,
    AddPartLogComponent
  ]
})
export class FormsModule { }
