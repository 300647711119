import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserVehicleService } from 'src/app/services/user-vehicle.service';
import { UserVehicle } from 'src/app/models/UserVehicle'

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss']
})
export class AddVehicleComponent implements OnInit {

  constructor(
    private userVehicleService: UserVehicleService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onClickClose() {
    this.onClose();
  }

  onDoneAddingVehicle(vehicle: UserVehicle) {
    if (!vehicle) {
      this.onClose();
      return;
    }
    this.userVehicleService.addVehicle(vehicle).subscribe((savedVehicle) =>
      this.setCurrentVehicleAndNavigateToTheDashboard(savedVehicle));
  }

  onClose() {
    this.router.navigate(['/garage']);
  }

  setCurrentVehicleAndNavigateToTheDashboard(userVehicle: UserVehicle) {
    this.userVehicleService.getUserVehicles().subscribe(userVehicles => {
      this.userVehicleService.setCurrentVehicle(userVehicle);
      this.router.navigate(['/dashboard']);
    });
  }

  

}
