export enum TemporalUnit {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR"
}

export namespace TemporalUnit {
    export function toArray(): TemporalUnit[] {
        let temporalUnits: TemporalUnit[] = [];
        for (let temporalUnit in TemporalUnit) {
            if (typeof TemporalUnit[temporalUnit] !== 'function') {
                temporalUnits.push(TemporalUnit[temporalUnit]);;
            }
        }
        return temporalUnits;
    }
}