import { Component, OnInit } from '@angular/core';

import { AccountSettings } from 'src/app/models/AccountSettings';
import { SubscriptionStatus } from 'src/app/models/SubscriptionStatus';
import { User } from 'src/app/models/User';
import { UpdatePassword } from 'src/app/models/forms/UpdatePassword';
import { AuthService } from 'src/app/services/auth.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
  private accountSettings: AccountSettings = new AccountSettings();
  private updatePassword: UpdatePassword = new UpdatePassword();
  private loggedInUser: User;
  private manageSubscriptionUrl: string;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.userService.getAccountSettings().subscribe(accountSettings => {
      this.accountSettings = accountSettings;
    });
    this.loggedInUser = this.authService.getLoggedInUser();
    if (this.authService.isLoggedIn() && this.loggedInUser.subscription.status !== SubscriptionStatus.NOT_STARTED) {
      this.subscriptionService.manageSubscription().subscribe(manageSubscription => {
        this.manageSubscriptionUrl = manageSubscription.sessionUrl;
      });
    }
  }

  onClickSave() {
    this.userService.updateName(this.accountSettings.name).subscribe(() => {
      this.updateLoggedInUserName(this.accountSettings.name);
      this.toastService.showSuccess("Saved successfully");
    });
  }

  onClickUpdate() {
    this.userService.updatePassword(this.updatePassword).subscribe(() => {
      this.toastService.showSuccess("Password updated successfully");
    });
  }

  onClickManageSubscription() {
    window.location.href = this.manageSubscriptionUrl;
  }

  updateLoggedInUserName(name: string) {
    const loggedInUser = this.authService.getLoggedInUser();
    loggedInUser.name = name;
    this.authService.setLoggedInUser(loggedInUser);
  }

}
