import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ForgotPassword } from 'src/app/models/forms/ForgotPassword';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';

const DEFAULT_ERROR_MESSAGE: string = "Something went wrong";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('forgotPasswordForm') form: NgForm;
  private forgotPassword: ForgotPassword = new ForgotPassword();
  private error: boolean = false;
  private errorMessage: string = DEFAULT_ERROR_MESSAGE;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onClickContinue() {
    if (!this.form.valid) {
      this.form.control.markAllAsTouched();
      return;
    }
    this.userService.forgotPassword(this.forgotPassword.email).subscribe(
      () => {
        this.router.navigate(['/login']);
        this.alertService.showSuccess("We've sent you an email.", "Please use the included link to reset your password.", "Got it!");
      },
      (err) => {
        this.error = true;
      });
  }

}
