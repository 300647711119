import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SharedUser } from 'src/app/models/SharedUser';

@Component({
  selector: 'app-share-vehicle-modal',
  templateUrl: './share-vehicle.component.html',
  styleUrls: ['./share-vehicle.component.scss']
})
export class ShareVehicleComponent implements OnInit {
  @Input() sharedUsers: SharedUser[] = [];
  @Input() readonly: boolean = false;
  @Output() closeEvent = new EventEmitter<any>();
  @Output() doneEvent = new EventEmitter<SharedUser[]>();
  @Output() sharedUsersUpdatedEvent = new EventEmitter<SharedUser[]>();

  constructor() { }

  ngOnInit() {
  }

  onClickClose() {
    this.closeEvent.emit();
  }

  onClickDone() {
    this.doneEvent.emit(this.sharedUsers);
  }

  onSharedUsersUpdatedEvent(sharedUsers: SharedUser[]) {
    this.sharedUsers = sharedUsers;
  }
}
