import * as moment from "moment";

const MONTHS_ABBREVIATED = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
]

const WEEKDAYS = [
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
]

export default class DateUtils {
    static isValid(date: any): boolean {
        return moment(date).isValid();
    }

    static isInPast(date: moment.Moment) {
        return !date.isAfter(moment());
    }

    static toIsoTimestamp(date: Date): string {
        return moment(date).startOf('day').utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
    }

    static getMonth(month: number): string {
        return MONTHS_ABBREVIATED[month - 1];
    }

    static getWeekdays(): string[] {
        return WEEKDAYS;
    }
}