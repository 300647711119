import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Alert } from 'src/app/models/Alert';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  @Input() alerts: Alert[] = [];
  @Output() dismissAlertEvent = new EventEmitter<Alert>();
  private visibleAlerts: Alert[] = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.visibleAlerts = this.alerts.slice(0, 5);
  }

  onClickDismissAlert(alert: Alert) {
    this.dismissAlertEvent.emit(alert);
  }

}
