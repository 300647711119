import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GasLog } from 'src/app/models/GasLog';
import { GasLogService } from 'src/app/services/gas-log.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-add-gas-log',
  templateUrl: './add-gas-log.component.html',
  styleUrls: ['./add-gas-log.component.scss']
})
export class AddGasLogComponent implements OnInit {
  private log: GasLog = new GasLog();

  constructor(
    private gasLogService: GasLogService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onClickClose() {
    this.onDone();
  }

  onDoneAddingLog(log: GasLog) {
    if (!log) {
      this.onDone();
      return;
    }
    this.addLog(log);
  }

  addLog(log: GasLog) {
    this.gasLogService.addLog(log).subscribe(
      () => this.onDone(),
      (err) => this.toastService.showSomethingWentWrong()
    );
  }

  onDone() {
    this.router.navigate(['/logs/gas']);
  }

}
