import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import Selectr from 'mobius1-selectr/src/selectr';

import { VehicleService } from 'src/app/services/vehicle.service';
import { UserVehicle } from 'src/app/models/UserVehicle';
import StringUtils from 'src/app/utils/string-utils';

@Component({
  selector: 'app-add-vehicle-form',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss']
})
export class AddVehicleComponent implements OnInit {
  @Output() doneEvent = new EventEmitter<UserVehicle>();
  @ViewChild('addVehicleForm') form: NgForm;
  @ViewChild('mileage') mileageInput: ElementRef;
  @ViewChild('milesDrivenPerYear') milesDrivenPerYearInput: ElementRef;
  private vehicle: UserVehicle = new UserVehicle();
  private makes: string[] = [];
  private models: string[] = [];
  private years: string[] = [];
  private formattedMileage: any;
  private formattedMilesDrivenPerYear: any;
  private makeSelector: Selectr;
  private modelSelector: Selectr;
  private isModelHidden: boolean = true;
  private yearSelector: Selectr;
  private isYearHidden: boolean = true;
  private isVehicleSelected: boolean = false;
  private useCustomVehicle: boolean = false;
  private isMileageEnabled: boolean = true;

  constructor(private vehicleService: VehicleService) { }

  ngOnInit(): void {
    this.vehicleService.getMakes().subscribe(makes => {
      this.makes = makes;
      this.initMakeSelector();
    });
  }

  initMakeSelector() {
    this.makeSelector = new Selectr(
      document.getElementById("makeSelector") as HTMLSelectElement, 
      {
        placeholder: 'Select make',
        defaultSelected: false,
        data: this.buildDataForSelector(this.makes)
      }
    );
    this.setHandlersForMakeSelector();
  }

  setHandlersForMakeSelector() {
    this.makeSelector.on('selectr.select', (selector) => {
      this.makeSelector.close();
      this.vehicle.make = selector.value;
      this.onMakeSelectorChange(this.vehicle.make);
    });
  }

  initModelSelector() {
    this.modelSelector = new Selectr(
      document.getElementById("modelSelector") as HTMLSelectElement, 
      {
        placeholder: 'Select model',
        defaultSelected: false,
        data: this.buildDataForSelector(this.models)
      }
    );
    this.setHandlersForModelSelector();
    this.isModelHidden = false;
  }

  setHandlersForModelSelector() {
    this.modelSelector.on('selectr.select', (selector) => {
      this.modelSelector.close();
      this.vehicle.model = selector.value;
      this.onModelSelectorChange(this.vehicle.model);
    });
  }

  initYearSelector() {
    this.yearSelector = new Selectr(
      document.getElementById("yearSelector") as HTMLSelectElement, 
      {
        placeholder: 'Select year',
        defaultSelected: false,
        data: this.buildDataForSelector(this.years)
      }
    );
    this.setHandlersForYearSelector();
    this.isYearHidden = false;
  }

  setHandlersForYearSelector() {
    this.yearSelector.on('selectr.select', (selector) => {
      this.yearSelector.close();
      this.vehicle.year = parseInt(selector.value);
      this.onYearSelectorChange();
    });
  }

  onMakeSelectorChange(make: string) {
    if (make) {
      this.vehicleService.getModels(make)
        .subscribe(models => {
          this.models = models;
          if (this.modelSelector) {
            this.reloadSelector(this.modelSelector, this.models);
          } else {
            this.initModelSelector();
          }
        });
    }
    this.isYearHidden = true;
    this.isVehicleSelected = false;
  }

  onModelSelectorChange(model: string) {
    if (model) {
      this.vehicleService.getYears(this.vehicle.make, model)
        .subscribe(years => {
          this.years = years;
          if (this.yearSelector) {
            this.isYearHidden = false;
            this.reloadSelector(this.yearSelector, this.years);
          } else {
            this.initYearSelector();
          }
        });
    }
    this.isVehicleSelected = false;
  }

  onYearSelectorChange() {
    this.isVehicleSelected = true;
  }

  onChangeMake() {
    this.isVehicleSelected = !!this.vehicle.make && !!this.vehicle.model && !!this.vehicle.year;
  }

  onChangeModel() {
    this.isVehicleSelected = !!this.vehicle.make && !!this.vehicle.model && !!this.vehicle.year;
  }

  onChangeYear() {
    this.isVehicleSelected = !!this.vehicle.make && !!this.vehicle.model && !!this.vehicle.year;
  }

  onChangeMileage() {
    const formattedMileage: any = StringUtils.formatNumber(this.mileageInput.nativeElement.value);
    this.mileageInput.nativeElement.value = formattedMileage;
    this.vehicle.mileage = formattedMileage.replace(",", "");
  }

  onChangeMilesDrivenPerYear() {
    const formattedMilesDrivenPerYear: any = StringUtils.formatNumber(this.milesDrivenPerYearInput.nativeElement.value);
    this.milesDrivenPerYearInput.nativeElement.value = formattedMilesDrivenPerYear;
    this.vehicle.milesDrivenPerYear = formattedMilesDrivenPerYear.replace(",", "");
  }

  onClickDontSeeYourVehicle() {
    this.useCustomVehicle = true
  }

  onClickNotSureLink() {
    this.isMileageEnabled = false;
  }

  onClickDone() {
    this.onFormSubmit();
    if (this.form.invalid) {
        return;
    }
    this.doneEvent.emit(this.vehicle);
  }

  onFormSubmit() {
    this.form.control.markAllAsTouched();
  }

  reloadSelector(selector: Selectr, data: any[]) {
    selector.removeAll();
    selector.add(
      this.buildDataForSelector(data)
    );
  }

  buildDataForSelector(list: any[]) {
    var data = [];
    for (var item of list) {
      data.push({
        text: item,
        value: item
      });
    }
    return data;
  }

}
