import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ReleaseNote } from 'src/app/models/ReleaseNote';
import DateUtils from 'src/app/utils/date-utils';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {
  private getReleaseNoteUrl = environment.apiUrl + '/release-notes/getById';
  private getReleaseNotesUrl = environment.apiUrl + '/release-notes/get';
  private addUrl = environment.apiUrl + '/release-notes/add';
  private updateUrl = environment.apiUrl + '/release-notes/update';
  private deleteUrl = environment.apiUrl + '/release-notes/delete';

  constructor(
    private http: HttpClient
  ) { }

  getReleaseNote(releaseNoteId: string): Observable<ReleaseNote> {
    const body = {
      releaseNoteId
    }
    return this.http.post<ReleaseNote>(this.getReleaseNoteUrl, body, httpOptions).pipe(
      map(releaseNote => {
        releaseNote.releaseDate = new Date(releaseNote.releaseDate);
        return releaseNote;
      })
    );
  }

  getReleaseNotes(): Observable<ReleaseNote[]> {
    return this.http.post<ReleaseNote[]>(this.getReleaseNotesUrl, null, httpOptions).pipe(
      map(releaseNotes => {
        for (const releaseNote of releaseNotes) {
          releaseNote.releaseDate = new Date(releaseNote.releaseDate);
        }
        return releaseNotes;
      })
    );
  }

  addReleaseNote(releaseNote: ReleaseNote) {
    let note: any = releaseNote;
    if (releaseNote.releaseDate) {
      note.releaseDate = DateUtils.toIsoTimestamp(releaseNote.releaseDate);
    }
    const body = releaseNote
    return this.http.post<any>(this.addUrl, body, httpOptions);
  }

  updateReleaseNote(releaseNote: ReleaseNote) {
    let note: any = releaseNote;
    if (releaseNote.releaseDate) {
      note.releaseDate = DateUtils.toIsoTimestamp(releaseNote.releaseDate);
    }
    const body = {
      releaseNoteId: releaseNote.id,
      data: note
    }
    return this.http.post<any>(this.updateUrl, body, httpOptions);
  }

  deleteReleaseNote(releaseNoteId: string) {
    const body = {
      releaseNoteId
    }
    return this.http.post<any>(this.deleteUrl, body, httpOptions);
  }
}
