import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { VehicleSettingsReminder as Reminder } from 'src/app/models/VehicleSettingsReminder';
import { TemporalUnit } from 'src/app/models/TemporalUnit';
import { UserVehicle } from 'src/app/models/UserVehicle';
import { AuthService } from 'src/app/services/auth.service';
import { UserVehicleService } from 'src/app/services/user-vehicle.service';
import StringUtils from 'src/app/utils/string-utils';
import { VehicleSettings } from 'src/app/models/VehicleSettings';
import { AlertService } from 'src/app/services/alert.service';

const DEFAULT_VEHICLE_DROPDOWN_OPTION = "Select a vehicle";

@Component({
  selector: 'app-reminders-settings',
  templateUrl: './reminders-settings.component.html',
  styleUrls: ['./reminders-settings.component.scss']
})
export class RemindersSettingsComponent implements OnInit {
  private addReminderModal: BsModalRef;
  private formDisabled: boolean = false;
  private vehicleDropdownValue: string = DEFAULT_VEHICLE_DROPDOWN_OPTION;
  private selectedReminder: Reminder;
  private userVehicles: UserVehicle[] = [];
  private vehicleSettings: VehicleSettings = new VehicleSettings();

  constructor(
    private userVehicleService: UserVehicleService,
    private authService: AuthService,
    private modalService: BsModalService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    const userVehicles = this.userVehicleService.getUserVehiclesFromLocalStorage();
    if (userVehicles) {
      this.userVehicles = userVehicles;
      this.getCurrentVehicleAndPopulateForm();
    } else {
      this.userVehicleService.getUserVehicles().subscribe(userVehicles => {
        this.userVehicles = userVehicles;
        this.getCurrentVehicleAndPopulateForm();
      });
    }
  }

  getCurrentVehicleAndPopulateForm() {
    const currentVehicle = this.userVehicleService.getCurrentVehicle();
    if (currentVehicle) {
      this.getVehicleSettingsAndPopulateForm(currentVehicle.id);
    } else {
      this.formDisabled = true;
    }
  }

  getVehicleSettingsAndPopulateForm(userVehicleId: string) {
    this.vehicleDropdownValue = userVehicleId;
    this.getVehicleSettings(userVehicleId);
  }

  getVehicleSettings(userVehicleId: string) {
    this.userVehicleService.getSettings(userVehicleId).subscribe(settings => {
      this.vehicleSettings = settings;
      this.formDisabled = false;
    });
  }

  onChangeSelectedVehicle() {
    if (this.vehicleDropdownValue === DEFAULT_VEHICLE_DROPDOWN_OPTION) {
      this.vehicleSettings = new VehicleSettings();
      this.formDisabled = true;
      return;
    }
    this.getVehicleSettingsAndPopulateForm(this.vehicleDropdownValue);
  }

  onClickReminder(reminder: Reminder, modal: TemplateRef<any>) {
    this.selectedReminder = reminder;
    this.addReminderModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickDeleteReminder(reminder: Reminder) {
    this.preventSelectionOfReminderWhenClickingOnButton(event);
    this.alertService.showAreYouSureYouWantToDelete()
      .then((result) => {
        if (result.value) {
          this.deleteReminder(reminder);
          this.updateReminders(() => {
            this.alertService.showDeleteConfirmation("This reminder has been deleted.");
          });
        }
      });
  }

  onClickAdd(modal: TemplateRef<any>) {
    this.addReminderModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseAddReminderModal() {
    this.hideAddReminderModal();
  }

  onDoneAddingReminder(reminder: Reminder) {
    this.vehicleSettings.reminders.push(reminder);
    this.updateReminders(() => {
      this.hideAddReminderModal();
    });
  }

  onDoneUpdatingReminder(reminder: Reminder) {
    const reminderToUpdate = this.vehicleSettings.reminders.find(r => r.message === reminder.message);
    var indexOfReminderToUpdate = this.vehicleSettings.reminders.indexOf(reminderToUpdate);
    this.vehicleSettings.reminders[indexOfReminderToUpdate] = reminder;
    this.updateReminders(() => {
      this.hideAddReminderModal();
    });
  }

  updateReminders(cb: any) {
    this.userVehicleService.updateReminders(this.vehicleSettings.id,
      this.vehicleSettings.reminders).subscribe(() => {
        cb();
        this.getVehicleSettings(this.vehicleSettings.id);
      });
  }

  deleteReminder(reminder: Reminder) {
    this.vehicleSettings.reminders = this.vehicleSettings.reminders
      .filter(r => r.message !== reminder.message);
  }

  hideAddReminderModal() {
    if (this.addReminderModal) {
      this.addReminderModal.hide();
    }
  }

  getReadableFrequencyText(reminder: Reminder) {
    let text = this.getReadableTimeFrequencyText(reminder);
    return StringUtils.capitalizeFirstLetter(text);
  }

  getReadableTimeFrequencyText(reminder: Reminder): string {
    let text = "";
    if (reminder.frequencyInTime === 1) {
      text = "every " + reminder.temporalUnit.toString().toLowerCase();
    } else if (reminder.frequencyInTime > 1) {
      text = "every " + reminder.frequencyInTime + " " + reminder.temporalUnit.toString().toLowerCase() + "s";
    }

    if (reminder.temporalUnit === TemporalUnit.WEEK) {
      text += " on " + reminder.dayOfWeek;
    } else if (reminder.temporalUnit === TemporalUnit.MONTH) {
      text += " on day " + reminder.dayOfMonth;
    }

    return text;
  }

  getVehicleDropdownDefaultValue() {
    return DEFAULT_VEHICLE_DROPDOWN_OPTION;
  }

  preventSelectionOfReminderWhenClickingOnButton(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

}
