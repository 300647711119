export default class Utils {
    static isEmpty(object: any): any {
        return object
        && Object.keys(object).length === 0
        && Object.getPrototypeOf(object) === Object.prototype
    }

    static clone(object: any): any {
        return Object.assign({}, object);
    }

    static arrayOfNumbers(size: number): number[] {
        return Array.from(Array(size).keys());
    }

    static commaSeparatedList(array: string[], includeSpace?: boolean): string {
        const padding = includeSpace ? " " : "";
        return array.join("," + padding);
    }

    static removeConsecutiveDuplicates(array: any[]) {
        return array.filter((item, pos, arr) => {
            // Always keep the 0th element as there is nothing before it
            // Then check if each element is different than the one before it
            return pos === 0 || item !== arr[pos - 1];
        });
    }

    static removeStringFromArray(array: string[], value: string) {
        return array = array.filter((i) => i !== value);
    }

    static removeLastXNumberOfElementsFromArray(array: Array<any>, numberToRemove: number): Array<any> {
        return array.splice(array.length - numberToRemove, numberToRemove);
    }

    static getByIdFromArray(array: Array<any>, id: string): any {
        return array.find(item => item.id === id);
    }
}