import { Component, OnInit } from '@angular/core';

import { NotificationSettings } from 'src/app/models/NotificationSettings';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
  private notificationSettings: NotificationSettings = new NotificationSettings();

  constructor(
    private userService: UserService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.userService.getAccountSettings().subscribe(accountSettings => {
      this.notificationSettings = accountSettings.notifications;
    });
  }

  onClickSave() {
    this.userService.updateNotificationSettings(this.notificationSettings).subscribe(() => {
      this.toastService.showSuccess("Updated successfully");
    });
  }

}
