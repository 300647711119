import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerSharedService {

  showSpinnerBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  show() {
    this.showSpinnerBehaviorSubject.next(true);
  }

  hide() {
    this.showSpinnerBehaviorSubject.next(false);
  }

  showSpinnerSubject() {
    return this.showSpinnerBehaviorSubject;
  }
}

