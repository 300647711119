import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import Utils from '../utils/utils';
import { AuthService } from './auth.service';
import { Checkout } from '../models/Checkout';
import { SubscriptionPlan } from '../models/SubscriptionPlan';
import { Subscription } from '../models/Subscription';
import { ManageSubscription } from '../models/ManageSubscription';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private subscribe2Url = environment.apiUrl + '/user/subscription/subscribe';
  private checkoutUrl = environment.apiUrl + '/user/subscription/checkout';
  private manageSubscriptionUrl = environment.apiUrl + '/user/subscription/manageSubscription';

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  subscribe(plan: SubscriptionPlan): Observable<any> {
    const body = {
      plan: plan
    }
    return this.http.post<any>(this.subscribe2Url, body, httpOptions);
  }
  
  checkout(plan: SubscriptionPlan): Observable<Checkout> {
    const body = {
      plan: plan
    }
    return this.http.post<any>(this.checkoutUrl, body, httpOptions);
  }

  manageSubscription(): Observable<ManageSubscription> {
    return this.http.post<any>(this.manageSubscriptionUrl, null, httpOptions);
  }
}
