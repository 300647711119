import { Injectable } from '@angular/core';
import swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  showAreYouSureYouWantToDelete() {
    return this.showAreYouSureYouWantTo('Yes, delete it!');
  }

  showDeleteConfirmation(text: string) {
    return this.showConfirmation('Deleted!', text);
  }

  showAreYouSureYouWantTo(confirmationButtonText: string) {
    return swal.fire({
        title: 'Are you sure?',
        text: "This action can't be undone.",
        type: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-danger',
        confirmButtonText: confirmationButtonText,
        cancelButtonClass: 'btn btn-primary'
    });
  }

  showQuestion(question: string, subtext?: string, confirmationButtonText?: string, cancelButtonText?: string) {
    return swal.fire({
        title: question,
        text: subtext,
        type: 'question',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-primary',
        confirmButtonText: confirmationButtonText ? confirmationButtonText : "Yes!",
        cancelButtonClass: 'btn btn-primary',
        cancelButtonText: cancelButtonText ? cancelButtonText : "Cancel"
    });
  }

  showConfirmation(title: string, text: string) {
    return swal.fire({
      title: title,
      text: text,
      type: 'success',
      buttonsStyling: false,
      confirmButtonClass: 'btn btn-primary'
    });
  }

  showSuccess(message: string, subtext?: string, confirmationButtonText?: string) {
    return swal.fire({
        title: message,
        html: subtext,
        type: 'success',
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-primary',
        confirmButtonText: confirmationButtonText ? confirmationButtonText : "OK",
    });
  }

  showInfo(title: string, text: string) {
    return swal.fire({
      title: title,
      html: text,
      type: 'info',
      buttonsStyling: false,
      confirmButtonClass: 'btn btn-primary'
    });
  }

}
