import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-add-custom-services-modal',
  templateUrl: './add-custom-services.component.html',
  styleUrls: ['./add-custom-services.component.scss']
})
export class AddCustomServicesComponent implements OnInit {
  @Output() closeEvent = new EventEmitter<any>();
  @Output() doneEvent = new EventEmitter<string[]>();

  constructor() { }

  ngOnInit() {}

  onClickClose() {
    this.closeEvent.emit();
  }

  onDone(services: string[]) {
    this.doneEvent.emit(services);
  }

}
