import { NgModule } from "@angular/core";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from "@angular/common";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TagInputModule } from 'ngx-chips';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { RouterModule } from "@angular/router";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { DxVectorMapModule } from "devextreme-angular";
import { BsDropdownModule } from "ngx-bootstrap";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { DirectivesModule } from 'src/app/directives/directives.module'
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";
import { AddedPartsTableComponent } from './added-parts-table/added-parts-table.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FilePreviewListComponent } from './file-preview-list/file-preview-list.component';
import { ShareVehicleComponent } from './share-vehicle/share-vehicle.component';
import { TableComponent } from './table/table.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertComponent } from './alert/alert.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { PartsSearchComponent } from './parts-search/parts-search.component';
import { AddCustomServicesComponent } from './add-custom-services/add-custom-services.component';
import { SharedModalsModule } from "../modals-shared/shared-modals.module";
import { AuthNavbarComponent } from './auth-navbar/auth-navbar.component';
import { AuthFooterComponent } from './auth-footer/auth-footer.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CollapseModule.forRoot(),
    DxVectorMapModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    NgbModule,
    TagInputModule,
    FormsModule,
    NgxDatatableModule,
    DirectivesModule,
    SharedModalsModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AddedPartsTableComponent,
    FileUploadComponent,
    FilePreviewListComponent,
    ShareVehicleComponent,
    TableComponent,
    PageHeaderComponent,
    AlertsComponent,
    AlertComponent,
    LineChartComponent,
    PartsSearchComponent,
    AddCustomServicesComponent,
    AuthNavbarComponent,
    AuthFooterComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AddedPartsTableComponent,
    FileUploadComponent,
    FilePreviewListComponent,
    ShareVehicleComponent,
    TableComponent,
    PageHeaderComponent,
    AlertsComponent,
    AlertComponent,
    LineChartComponent,
    PartsSearchComponent,
    AddCustomServicesComponent,
    AuthNavbarComponent,
    AuthFooterComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class ComponentsModule {}
