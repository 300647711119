import { Component, Input, OnInit, Output, ViewChild, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import Selectr from 'mobius1-selectr/src/selectr';

import { Part } from 'src/app/models/Part';
import StringUtils from 'src/app/utils/string-utils';
import { TemporalUnit } from 'src/app/models/TemporalUnit';
import { AvailablePartsService } from 'src/app/services/available-parts.service';

@Component({
  selector: 'app-add-part-form',
  templateUrl: './add-part.component.html',
  styleUrls: ['./add-part.component.scss']
})
export class AddPartComponent implements OnInit, AfterViewInit {
  @Input() part: Part = new Part();
  @Input() viewOnly: boolean = false;
  @Output() doneEvent = new EventEmitter<Part>();
  @Output() doneEditingEvent = new EventEmitter<Part>();
  @ViewChild('addPartsForm') form: NgForm;
  @ViewChild('cost') costInput: ElementRef;
  private partSelector: Selectr;
  private isPartTypeValid: boolean = true;
  private availableParts: string[] = [];
  private isEdit: boolean = false;
  private useCustomPart: boolean = false;

  constructor(private availablePartsService: AvailablePartsService) { }

  ngOnInit(): void {
    if (this.part.type) {
      this.isEdit = true;
    }

    this.availablePartsService.getParts()
      .subscribe(parts => {
        this.availableParts = parts;
        if (this.isEdit && !parts.includes(this.part.type)) {
          this.useCustomPart = true;
        } else {
          this.initPartSelector();
        }
      });

    this.setDefaults();
  }

  ngAfterViewInit() {
    if (this.part.cost) {
      this.costInput.nativeElement.value = StringUtils.formatCurrencyWithDefaultDecimal(this.part.cost);
    }
  }

  setDefaults() {
    if (!this.part.quantity) {
      this.part.quantity = 1;
    }
  }

  initPartSelector() {
    this.partSelector = new Selectr(
      document.getElementById("partSelector") as HTMLSelectElement, 
      {
        multiple: false,
        defaultSelected: false,
        placeholder: 'Select part',
        clearable: this.part.type ? false : true,
        closeOnScroll: true,
        data: this.buildDataForPartSelector()
      }
    );
    if (this.part && this.part.type) {
      this.partSelector.setValue(this.part.type);
    }
    this.setHandlersForPartSelector();
    if (this.viewOnly) {
      this.partSelector.disable();
    }
  }

  setHandlersForPartSelector() {
    this.partSelector.on('selectr.select', (selector) => {
      this.part.type = selector.value;
      this.validateForm();
    });
  }

  onChangeCost() {
    const formattedCost: any = StringUtils.formatCurrencyWithDefaultDecimal(this.costInput.nativeElement.value);
    this.costInput.nativeElement.value = formattedCost;
    this.part.cost = StringUtils.formatCurrencyAsNumber(formattedCost);
  }

  onClickAddCustomPartLink() {
    this.useCustomPart = true;
  }

  onClickPurchasedWarrantyCheckbox() {
    if (this.part.hasWarranty) {
      this.part.warrantyTemporalUnit = this.getTemporalUnits()[0];
    } else {
      this.part.warrantyTerm = undefined;
      this.part.warrantyTemporalUnit = undefined;
    }
  }

  onClickDone() {
    this.onFormSubmit();
    if (!this.isFormValid()) {
      return;
    }
    if (this.isEdit) {
      this.doneEditingEvent.emit(this.part);
      return;
    }
    this.doneEvent.emit(this.part);
  }

  onFormSubmit() {
    this.form.control.markAllAsTouched();
    this.validateForm();
  }

  isFormValid() {
    return this.form.valid && this.isPartTypeValid;
  }

  validateForm() {
    this.isPartTypeValid = true;
    if (!this.part.type) {
      this.isPartTypeValid = false;
    }
  }

  resetForm() {
    this.form.reset();
    this.partSelector.clear();
  }

  buildDataForPartSelector() {
    let data = [];
    for (let part of this.availableParts) {
      data.push({
        text: part,
        value: part
      })
    }
    return data;
  }

  getTemporalUnits() {
    return TemporalUnit.toArray();
  }

}
