import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

import { environment } from 'src/environments/environment';
import { RouteHistory } from 'src/app/services/route-history.service';
import { SpinnerSharedService } from "./services/shared/spinner.shared.service";
import { AuthService } from "./services/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private routeHistory: RouteHistory,
    private spinner: NgxSpinnerService,
    private spinnerSharedService: SpinnerSharedService,
    private authService: AuthService
  ) {
    this.routeHistory.recordHistory();
    this.spinnerSharedService.showSpinnerSubject().subscribe(show => {
      if (show) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }

  ngOnInit() {
    const lastForcedLogoutVersion = localStorage.getItem('lastForcedLogoutVersion');
    const needsForcedLogout = (!lastForcedLogoutVersion || (lastForcedLogoutVersion !== environment.lastForceLogoutVersion));
    if (needsForcedLogout) {
      this.forceLogout();
    }
  }

  forceLogout() {
    this.authService.logout();
    localStorage.setItem('lastForcedLogoutVersion', environment.lastForceLogoutVersion);
  }
}
