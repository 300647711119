import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { PurchaseInformation } from 'src/app/models/PurchaseInformation';
import { ServiceInformation } from 'src/app/models/ServiceInformation';

export class VehicleDetails {
    licensePlate: string;
    vin: string;
    color: string;
    //purchaseInformation: PurchaseInformation = new PurchaseInformation();
    serviceInformation: ServiceInformation = new ServiceInformation();
    notes: string;
    //files: UploadedFile[];
}