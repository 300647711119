import { NgIfContext } from '@angular/common';
import { Directive, AfterContentChecked, TemplateRef, ViewContainerRef, Input} from '@angular/core';

@Directive({
  selector: '[ngxResponsiveColumn]'
})
export class NgxResponsiveColumnDirective implements AfterContentChecked {
  private _visible = false;
  private _threshold: string;
  private _context: NgIfContext = new NgIfContext();

  @Input() 
  set ngxResponsiveColumn(value: string) {
    this._threshold = value;
  }

  @Input() 
  set ngxResponsiveColumnCondition(condition: any) {
    this._context.$implicit = condition;
  }

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef
  ) {}

  ngAfterContentChecked() {
    // Precondition
    if (!this._context.$implicit) {
      this.hide();
      return;
    }

    if (window.innerWidth >= 1480) {  // xl
      if (this._threshold === "xl" || this._threshold === "lg" || this._threshold === "md" || this._threshold === "sm" || this._threshold === "xs") {
        this.show();
      } else {
        this.hide();
      }
    } else if (window.innerWidth >= 1200 && window.innerWidth <= 1479) { // lg
      if (this._threshold === "lg" || this._threshold === "md" || this._threshold === "sm" || this._threshold === "xs") {
        this.show();
      } else {
        this.hide();
      }
    } else if (window.innerWidth >= 992 && window.innerWidth <= 1199) { // md
      if (this._threshold === "md" || this._threshold === "sm" || this._threshold === "xs") {
        this.show();
      } else {
        this.hide();
      }
    } else if (window.innerWidth >= 768 && window.innerWidth <= 991) { // sm
      if (this._threshold === "sm" || this._threshold === "xs") {
        this.show();
      } else {
        this.hide();
      }
    } else if (window.innerWidth <= 767) { // xs
      if (this._threshold === "xs") {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  show() {
    if (this._visible) {
      return;
    }
    this._viewContainer.createEmbeddedView(this._templateRef);
    this._visible = true;
  }

  hide() {
    if (!this._visible) {
      return;
    }
    this._viewContainer.clear();
    this._visible = false;
  }

}