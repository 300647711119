import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule as AngularFormsModule } from '@angular/forms';

import { ComponentsModule } from '../components/components.module';
import { AddPartComponent } from './add-part/add-part.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    AngularFormsModule,
    ComponentsModule
  ],
  declarations: [
    AddPartComponent
  ],
  exports: [
    AddPartComponent
  ]
})
export class SharedFormsModule { }
