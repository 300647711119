import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxResponsiveColumnDirective } from 'src/app/directives/ngx-responsive-column.directive';
import { FallbackImageDirective } from './fallback-image.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NgxResponsiveColumnDirective,
    FallbackImageDirective
  ],
  exports: [
    NgxResponsiveColumnDirective,
    FallbackImageDirective
  ],
})
export class DirectivesModule { }
