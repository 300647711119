import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { PartLog } from 'src/app/models/PartLog';
import { AlertService } from 'src/app/services/alert.service';
import { PartLogService } from 'src/app/services/part-log.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-edit-part-log',
  templateUrl: './edit-part-log.component.html',
  styleUrls: ['./edit-part-log.component.scss']
})
export class EditPartLogComponent implements OnInit {
  private log: PartLog;
  private dataLoaded: boolean = false;
  private viewOnly: boolean = true;
  private editMode: boolean = false;

  constructor(
    private partLogService: PartLogService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.partLogService.getLog(params.id).subscribe((log: PartLog) => {
          this.log = log;
          this.dataLoaded = true;
        });
      }
    )
  }

  onClickEdit() {
    this.toggleEditMode();
  }

  onClickDelete() {
    this.alertService.showAreYouSureYouWantToDelete()
      .then((result) => {
        if (result.value) {
          this.partLogService.deleteLog(this.log.id).subscribe(() => {
            this.alertService.showDeleteConfirmation("This log has been deleted.");
            this.router.navigate(['/logs/parts']);
          });
        }
      });
  }

  onClickClose() {
    this.onDone();
  }

  onDoneUpdatingLog(log: PartLog) {
    if (this.viewOnly) {
      this.onDone();
      return;
    }
    this.partLogService.updateLog(log).subscribe(() => {
      this.onDone();
      this.toastService.showSuccess("Updated successfully");
    });
  }

  onDone() {
    this.router.navigate(['/logs/parts']);
  }

  toggleEditMode() {
    this.viewOnly = false;
    this.editMode = true;
  }

}
