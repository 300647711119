import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { NgForm } from '@angular/forms';
import Quill from "quill";
import * as moment from 'moment';

import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { VehicleDetails } from 'src/app/models/VehicleDetails';
import { ToastService } from 'src/app/services/toast.service';
import { UserVehicleService } from 'src/app/services/user-vehicle.service';
import { TirePressures } from 'src/app/models/TirePressures';
import { ServiceInformation } from 'src/app/models/ServiceInformation';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  @ViewChild('detailsForm') detailsForm: NgForm;
  private notesTextEditor: Quill;
  private isIdentificationCollapsed: boolean = true;
  private isPurchaseInformationCollapsed: boolean = true;
  private isServiceInformationCollapsed: boolean = true;
  private isNotesCollapsed: boolean = true;
  private isInEditMode: boolean = false;
  private isFinancingChecked: boolean = false;
  private details: VehicleDetails = new VehicleDetails();
  private uploadFilesModal: BsModalRef;

  constructor(
    private userVehicleService: UserVehicleService,
    private toast: ToastService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.initNotesTextEditor();
    this.userVehicleService.getDetails().subscribe((details: VehicleDetails) => {
      this.details = details;
      this.setInitialFormValues();
      this.setInitialValueForTextEditor(this.details.notes);
    });
  }

  initNotesTextEditor() {
    this.notesTextEditor = new Quill("#notesTextEditor", {
      modules: {
        toolbar: [
          ["bold", "italic"],
          [
            {
              list: "ordered"
            },
            {
              list: "bullet"
            }
          ]
        ]
      },
      theme: "snow"
    });
    this.setHandlersForNotesTextEditor();
    this.notesTextEditor.disable();
  }

  setHandlersForNotesTextEditor() {
    this.notesTextEditor.on('text-change', (delta, oldDelta, source) => {
      const textLimit = 1000;
      if (this.notesTextEditor.getLength() > textLimit) {
        this.notesTextEditor.deleteText(textLimit, this.notesTextEditor.getLength());
      }
      this.details.notes = this.notesTextEditor.root.innerHTML;
    });
  }

  setInitialValueForTextEditor(value: string) {
    this.notesTextEditor.enable();
    var delta = this.notesTextEditor.clipboard.convert(value);
    this.notesTextEditor.setContents(delta);
    this.notesTextEditor.disable();
  }

  setInitialFormValues() {
    if (!this.details.serviceInformation) {
      this.details.serviceInformation = new ServiceInformation();
    }
    if (!this.details.serviceInformation.tirePressures) {
      this.details.serviceInformation.tirePressures = new TirePressures();
    }
  }

  onClickEdit() {
    this.isInEditMode = true;
    this.notesTextEditor.enable();
  }

  onClickSaveChanges() {
    this.userVehicleService.updateDetails(this.details).subscribe(() => {
      this.isInEditMode = false;
      this.notesTextEditor.disable();
      this.toast.showSuccess('Saved successfully');
    });
  }

  onClickCollapseAll() {
    this.isIdentificationCollapsed = true;
    this.isPurchaseInformationCollapsed = true;
    this.isServiceInformationCollapsed = true;
    this.isNotesCollapsed = true;
  }

  onClickUploadFiles(modal: TemplateRef<any>) {
    this.uploadFilesModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  // onClickCloseUploadFilesModal() {
  //   this.closeUploadFilesModal();
  // }

  isAtLeastOneCollapseExpanded() {
    return !this.isIdentificationCollapsed ||
      !this.isPurchaseInformationCollapsed ||
      !this.isServiceInformationCollapsed ||
      !this.isNotesCollapsed;
  }

  // onDoneUploadingFiles(files: UploadedFile[]) {
  //   this.closeUploadFilesModal();
  //   if (files && files.length > 0) {
  //     this.details.files.push(...files);
  //     this.userVehicleService.updateDetails(this.details).subscribe(() => {});
  //   }
  // }

  // closeUploadFilesModal() {
  //   this.uploadFilesModal.hide();
  // }

  // onDeleteFile(file: UploadedFile) {
  //   this.deleteFile(file);
  //   this.userVehicleService.updateDetails(this.details).subscribe(() => {});
  // }

  // deleteFile(file: UploadedFile) {
  //   this.details.files = this.details.files.filter(f => f.id !== file.id);
  // }

  // formatFormValues() {
  //   this.formatPurchasedDate();
  // }

  // formatPurchasedDate() {
  //   if (this.details.purchaseInformation.purchasedDate) {
  //     this.detailsForm.controls['purchasedDate']
  //       .setValue(moment(this.details.purchaseInformation.purchasedDate).format("YYYY-MM-DD"));
  //   }
  // }

}
