import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Contact } from 'src/app/models/forms/Contact';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

const SUBJECTS: string[] = [
  "Reporting an issue or bug",
  "Regarding my account",
  "Issue with my subscription",
  "Feature request",
  "Feedback",
  "Other"
]

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {
  @ViewChild('contactForm') form: NgForm;
  private contact: Contact = new Contact();
  private subjects: string[] = SUBJECTS;
  private message: HTMLFormElement;
  

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    const loggedInUser = this.authService.getLoggedInUser();
    if (loggedInUser) {
      this.userService.getAccountSettings().subscribe(accountSettings => {
        this.contact.name = accountSettings.name;
        this.contact.email = accountSettings.email;
      });
    }
  }

  ngAfterViewInit() {
    this.message = document.getElementById("message") as HTMLFormElement;
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        const subject = params.subject;
        if (subject) {
          this.contact.subject = subject;
          this.message.focus();
        }
      }
    );
  }

  onClickSend() {
    this.form.control.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    this.userService.contact(this.contact.name, this.contact.email, this.contact.subject, this.contact.message)
      .subscribe(() => {
        this.router.navigate(['/login']);
        this.alertService.showSuccess("Sent!", "We'll get back to you as soon as we can.", "Thanks!");
      });
  }

}
