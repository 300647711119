import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PartLog } from 'src/app/models/PartLog';
import { PartLogService } from 'src/app/services/part-log.service';
import { PartService } from 'src/app/services/part.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-add-part-log',
  templateUrl: './add-part-log.component.html',
  styleUrls: ['./add-part-log.component.scss']
})
export class AddPartLogComponent implements OnInit {
  private log: PartLog = new PartLog();

  constructor(
    private partLogService: PartLogService,
    private partService: PartService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onClickClose() {
    this.onDone();
  }

  onDoneAddingLog(log: PartLog) {
    if (!log) {
      this.onDone();
      return;
    }
    this.addLog(log);
  }

  addLog(log: PartLog) {
    this.partLogService.addLog(log).subscribe(
      () => this.onDone(),
      (err) => this.toastService.showSomethingWentWrong()
    );
  }

  onDone() {
    this.router.navigate(['/logs/parts']);
  }

}
