import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import * as moment from "moment";

import { User } from '../models/User'
import { AuthSharedService } from './shared/auth.shared.service';
import { LoginUrls } from 'src/app/models/LoginUrls';
import { UserSession } from '../models/UserSession';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private getLoginUrlsUrl = environment.apiUrl + '/user/getLoginUrls';
  private loginUrl = environment.apiUrl + '/user/login';
  private loginWithGoogleUrl = environment.apiUrl + '/user/loginWithGoogle';
  private loginWithFacebookUrl = environment.apiUrl + '/user/loginWithFacebook';

  constructor(
    private http: HttpClient,
    private authSharedService: AuthSharedService
  ) { }

  getLoginUrls(queryParams?: string): Observable<LoginUrls> {
    const body = {
      queryParams
    };
    return this.http.post<any>(this.getLoginUrlsUrl, body, httpOptions);
  }

  getLoggedInUser(): User {
    return JSON.parse(localStorage.getItem('user')) as User;
  }

  setLoggedInUser(user: User) {
    this.authSharedService.setLoggedInUser(user);
    localStorage.setItem("user", JSON.stringify(user));
  }

  login(email: string, password: string): Observable<UserSession> {
    const body = {
      email,
      password
    };
    return this.http.post<any>(this.loginUrl, body, httpOptions)
      .pipe(tap(res => this.setSession(res)))
      .pipe(shareReplay(1));
  }

  loginWithGoogle(code: string, queryParams?: string): Observable<UserSession> {
    const body = {
      code,
      queryParams
    };
    return this.http.post<any>(this.loginWithGoogleUrl, body, httpOptions)
      .pipe(tap(res => this.setSession(res)))
      .pipe(shareReplay(1));
  }

  loginWithFacebook(code: string): Observable<UserSession> {
    const body = {
      code
    };
    return this.http.post<any>(this.loginWithFacebookUrl, body, httpOptions)
      .pipe(tap(res => this.setSession(res)))
      .pipe(shareReplay(1));
  }

  setSession(session: UserSession) {
    if (session == null) {
      return;
    }
    const expiresAt = moment().add(session.token.expiresAt, 'second');
    localStorage.setItem('jwt', session.token.jwt);
    localStorage.setItem("expiresAt", JSON.stringify(expiresAt.valueOf()));
    this.setLoggedInUser(session.user);
  }

  logout() {
    localStorage.removeItem('jwt');
    localStorage.removeItem('expiresAt');
  }

  isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  getExpiration() {
    const expiration = localStorage.getItem("expiresAt");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }
}