import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IncidentLog } from 'src/app/models/IncidentLog';
import { IncidentLogService } from 'src/app/services/incident-log.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-add-incident-log',
  templateUrl: './add-incident-log.component.html',
  styleUrls: ['./add-incident-log.component.scss']
})
export class AddIncidentLogComponent implements OnInit {
  private log: IncidentLog = new IncidentLog();

  constructor(
    private incidentLogService: IncidentLogService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onClickClose() {
    this.onDone();
  }

  onDoneAddingLog(log: IncidentLog) {
    if (!log) {
      this.onDone();
      return;
    }
    this.addLog(log);
  }

  addLog(log: IncidentLog) {
    this.incidentLogService.addLog(log).subscribe(
      () => this.onDone(),
      (err) => this.toastService.showSomethingWentWrong()
    );
  }

  onDone() {
    this.router.navigate(['/logs/incidents']);
  }

}
