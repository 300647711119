import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { RegistrationLog } from 'src/app/models/RegistrationLog';
import { UserVehicleService } from 'src/app/services/user-vehicle.service';
import DateUtils from 'src/app/utils/date-utils';
import ApiRequestHelper from '../utils/api-request-helper';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class RegistrationLogService {
  private getLogUrl = environment.apiUrl + '/user/vehicle/logs/getById';
  private getLogsUrl = environment.apiUrl + '/user/vehicle/logs/get';
  private addUrl = environment.apiUrl + '/user/vehicle/logs/add';
  private updateUrl = environment.apiUrl + '/user/vehicle/logs/update';
  private deleteUrl = environment.apiUrl + '/user/vehicle/logs/delete';

  constructor(
    private http: HttpClient,
    private userVehicleService: UserVehicleService
  ) { }

  getLog(logId: string): Observable<RegistrationLog> {
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      logId
    }
    return this.http.post<RegistrationLog>(this.getLogUrl, body, httpOptions).pipe(
      map(log => {
        log.date = new Date(log.date);
        if (log.emissionsCompletedDate) {
          log.emissionsCompletedDate = new Date(log.emissionsCompletedDate);
        }
        return log;
      })
    );
  }

  getLogs(): Observable<RegistrationLog[]> {
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      type: "REGISTRATION"
    }
    return this.http.post<RegistrationLog[]>(this.getLogsUrl, body, httpOptions).pipe(
      map(logs => {
        for (const log of logs) {
          log.date = new Date(log.date);
          if (log.emissionsCompletedDate) {
            log.emissionsCompletedDate = new Date(log.emissionsCompletedDate);
          }
        }
        return logs;
      })
    );
  }

  addLog(registrationLog: RegistrationLog) {
    let log: any = registrationLog;
    if (registrationLog.date) {
      log.date = DateUtils.toIsoTimestamp(registrationLog.date);
    }
    if (registrationLog.emissionsCompletedDate) {
      log.emissionsCompletedDate = DateUtils.toIsoTimestamp(registrationLog.emissionsCompletedDate);
    }
    if (registrationLog.files) {
      log.files = ApiRequestHelper.getIdsFromObjects(registrationLog.files);
    }
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      type: "REGISTRATION",
      data: log
    }
    return this.http.post<any>(this.addUrl, body, httpOptions);
  }

  updateLog(registrationLog: RegistrationLog) {
    let log: any = registrationLog;
    if (registrationLog.date) {
      log.date = DateUtils.toIsoTimestamp(registrationLog.date);
    }
    if (registrationLog.emissionsCompletedDate) {
      log.emissionsCompletedDate = DateUtils.toIsoTimestamp(registrationLog.emissionsCompletedDate);
    }
    if (registrationLog.files) {
      log.files = ApiRequestHelper.getIdsFromObjects(registrationLog.files);
    }
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      logId: registrationLog.id,
      data: log
    }
    return this.http.post<any>(this.updateUrl, body, httpOptions);
  }

  deleteLog(logId: string) {
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      logId
    }
    return this.http.post<any>(this.deleteUrl, body, httpOptions);
  }
}
