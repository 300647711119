import { formatNumber, formatCurrency } from '@angular/common';
import * as moment from 'moment';

const DEFAULT_LOCALE = 'en-US'

export default class StringUtils {
    static isEmail(email: string): boolean {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }

    static isCurrency(value: string): boolean {
        const regex  = /^\d+(?:\.\d{0,2})$/;
        return regex.test(value);
    }

    static formatTitle(title: string): string {
        return title
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    
    static formatNumber(number: number): string {
        return formatNumber(number, DEFAULT_LOCALE, '1.0-0');
    }
    
    static formatNumberWithCommas(value: any): string {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    static formatCurrency(number: number, symbol: boolean = false): string {
        const currency = symbol ? '$' : '';
        let value = formatCurrency(number, DEFAULT_LOCALE, currency, 'USD');
        return value;
    }

    static formatCurrencyAsNumber(value: string): string {
        return value.replace(",", "");
    }

    static formatCurrencyWithDefaultDecimal(value: string): string {
        value = value
            .replace('.', '')
            .replace(/^0+/, '');
        if (value.length === 1 || value.length === 2) {
            value = '.' + value;
        } else if (value.length > 2) {
            const index = value.length - 2;
            value = value.substring(0, index) + '.' + value.substring(index);
            value = StringUtils.formatNumberWithCommas(value);
        }
        if (!value) {
            return "0";
        }
        return value;
    }

    static formatCurrencyWithExtendedDefaultDecimal(value: string): string {
        value = value
            .replace('.', '')
            .replace(/^0+/, '');
        if (value.length === 1 || value.length === 2 || value.length === 3) {
            value = '.' + value;
        } else if (value.length > 3) {
            const index = value.length - 3;
            value = value.substring(0, index) + '.' + value.substring(index);
            value = StringUtils.formatNumberWithCommas(value);
        }
        return value;
    }

    static formatDate(date: Date): string {
        return date.toLocaleString(DEFAULT_LOCALE).split(',')[0];
    }

    static formatDateAsPrintedMonthDayYear(date: Date): string {
        return moment(date).format('MMMM D, YYYY');
    }
    
    static formatDateForInput(date: Date): string {
        return moment(date).format("YYYY-MM-DD");
    }
    
    static capitalizeFirstLetter(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}