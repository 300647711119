import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Part } from 'src/app/models/Part';
import { Options } from 'src/app/models/options/Options';
import { UserVehicleService } from 'src/app/services/user-vehicle.service';
import ApiRequestHelper from 'src/app/utils/api-request-helper';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class PartService {
  private getPartUrl = environment.apiUrl + '/user/vehicle/parts/getById';
  private getPartsUrl = environment.apiUrl + '/user/vehicle/parts/get';
  private addPartsUrl = environment.apiUrl + '/user/vehicle/parts/add';
  private updatePartUrl = environment.apiUrl + '/user/vehicle/parts/update';
  private deletePartUrl = environment.apiUrl + '/user/vehicle/parts/delete';

  constructor(
    private http: HttpClient,
    private userVehicleService: UserVehicleService
  ) { }

  getParts(): Observable<Part[]> {
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
    }
    return this.http.post<Part[]>(this.getPartsUrl, body, httpOptions);
  }
}
