import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { TemporalUnit } from 'src/app/models/TemporalUnit';
import Utils from 'src/app/utils/utils';
import { VehicleSettingsReminder } from 'src/app/models/VehicleSettingsReminder';
import DateUtils from 'src/app/utils/date-utils';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-reminder-modal',
  templateUrl: './add-reminder.component.html',
  styleUrls: ['./add-reminder.component.scss']
})
export class AddReminderComponent implements OnInit {
  @Input() reminder: VehicleSettingsReminder = new VehicleSettingsReminder();
  @Output() closeEvent = new EventEmitter<any>();
  @Output() doneEvent = new EventEmitter<VehicleSettingsReminder>();
  @ViewChild('addReminderForm') form: NgForm;
  private updatingExistingReminder: boolean = false;

  constructor() { }

  ngOnInit() {
    if (!this.reminder.message) {
      this.updatingExistingReminder = false;
      this.initFormWithDefaultValues();
    } else {
      this.updatingExistingReminder = true;
    }
  }

  initFormWithDefaultValues() {
    this.reminder.frequencyInTime = 1;
    this.reminder.temporalUnit = this.getTemporalUnits()[0];
  }

  onClickClose() {
    this.closeEvent.emit();
  }

  onClickCancel() {
    this.closeEvent.emit();
  }

  onClickDone() {
    if (!this.form.valid) {
      this.form.control.markAllAsTouched();
      return;
    }
    this.doneEvent.emit(this.reminder);
  }

  onChangeTemporalUnit() {
    if (this.reminder.temporalUnit === TemporalUnit.WEEK) {
      this.reminder.dayOfWeek = this.getWeekdays()[0];
      this.reminder.dayOfMonth = undefined;
    } else if (this.reminder.temporalUnit === TemporalUnit.MONTH) {
      this.reminder.dayOfMonth = 1;
      this.reminder.dayOfWeek = undefined;
    } else {
      this.reminder.dayOfMonth = undefined;
      this.reminder.dayOfWeek = undefined;
    }
  }

  getTemporalUnits() {
    return TemporalUnit.toArray();
  }
  
  arrayOfNumbers(size: number) {
    return Utils.arrayOfNumbers(size);
  }

  getWeekdays() {
    return DateUtils.getWeekdays();
  }

}
