import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { RegistrationLog } from 'src/app/models/RegistrationLog';
import { RegistrationLogService } from 'src/app/services/registration-log.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-add-registration-log',
  templateUrl: './add-registration-log.component.html',
  styleUrls: ['./add-registration-log.component.scss']
})
export class AddRegistrationLogComponent implements OnInit {
  private log: RegistrationLog = new RegistrationLog();

  constructor(
    private registrationLogService: RegistrationLogService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onClickClose() {
    this.onDone();
  }

  onDoneAddingLog(log: RegistrationLog) {
    if (!log) {
      this.onDone();
      return;
    }
    this.addLog(log);
  }

  addLog(log: RegistrationLog) {
    this.registrationLogService.addLog(log).subscribe(
      () => this.onDone(),
      (err) => this.toastService.showSomethingWentWrong()
    );
  }

  onDone() {
    this.router.navigate(['/logs/registration']);
  }

}
