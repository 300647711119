import { Component, OnInit } from '@angular/core';
import { UserPreferences } from 'src/app/models/UserPreferences';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-preferences-settings',
  templateUrl: './preferences-settings.component.html',
  styleUrls: ['./preferences-settings.component.scss']
})
export class PreferencesSettingsComponent implements OnInit {
  private preferences: UserPreferences = new UserPreferences();

  constructor(
    private userService: UserService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.userService.getAccountSettings().subscribe(accountSettings => {
      this.preferences = accountSettings.preferences;
    });
  }

  onClickSave() {
    this.userService.updatePreferences(this.preferences).subscribe(() => {
      this.toastService.showSuccess("Updated successfully");
    });
  }

}
