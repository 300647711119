import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Selectr from 'mobius1-selectr/src/selectr';

import { VehicleSettingsService } from 'src/app/models/VehicleSettingsService';
import { TemporalUnit } from 'src/app/models/TemporalUnit';
import { AvailableServicesService } from 'src/app/services/available-services.service';
import Utils from 'src/app/utils/utils';
import StringUtils from 'src/app/utils/string-utils';
import { NgForm } from '@angular/forms';

const MILEAGE_FREQUENCY_OPTIONS: number[] = [
  1000,
  2000,
  3000,
  4000,
  5000,
  6000,
  7000,
  8000,
  9000,
  10000,
  12000,
  15000,
  20000,
  25000,
  30000,
  35000,
  40000,
  45000,
  50000,
  60000,
  70000,
  80000,
  90000,
  100000
]

@Component({
  selector: 'app-add-service-modal',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss']
})
export class AddServiceComponent implements OnInit {
  @Input() service: VehicleSettingsService = new VehicleSettingsService();
  @Input() existingServices: VehicleSettingsService[] = [];
  @Output() closeEvent = new EventEmitter<any>();
  @Output() doneEvent = new EventEmitter<VehicleSettingsService>();
  @ViewChild('addServiceForm') form: NgForm;
  private serviceSelector: Selectr;
  private isServiceValid: boolean = true;
  private updatingExistingService: boolean = false;
  private availableServices: string[] = [];
  private useCustomService: boolean = false;

  constructor(private availableServicesService: AvailableServicesService) { }

  ngOnInit() {
    if (!this.service.name) {
      this.updatingExistingService = false;
      this.initFormWithDefaultValues();
    } else {
      this.updatingExistingService = true;
    }

    this.availableServicesService.getServices()
      .subscribe(services => {
        this.availableServices = this.getAvailableServices(services);
        if (this.updatingExistingService && !this.availableServices.includes(this.service.name)) {
          this.useCustomService = true;
        } else {
          this.initServiceSelector();
        }
      });
  }

  initFormWithDefaultValues() {
    this.service.frequencyInMilesEnabled = true;
    this.service.frequencyInMiles = this.getMileageFrequencyOptions()[0];
    this.service.frequencyInTimeEnabled = true;
    this.service.frequencyInTime = 1;
    this.service.temporalUnit = this.getTemporalUnits()[0];
  }

  initServiceSelector() {
    this.serviceSelector = new Selectr(
      document.getElementById("serviceSelector") as HTMLSelectElement, 
      {
        placeholder: 'Select a service',
        defaultSelected: false,
        data: this.buildDataForServiceSelector(this.availableServices)
      }
    );
    if (this.updatingExistingService) {
      this.serviceSelector.setValue(this.service.name);
      this.serviceSelector.disable();
    }
    this.setHandlersForServiceSelector();
  }

  setHandlersForServiceSelector() {
    this.serviceSelector.on('selectr.select', (selector) => {
      this.service.name = selector.value;
    });
  }

  onClickClose() {
    this.closeEvent.emit();
  }

  onClickAddCustomServiceLink() {
    this.useCustomService = true;
  }

  onClickCancel() {
    this.closeEvent.emit();
  }

  onClickDone() {
    this.form.control.markAllAsTouched();
    this.onFormSubmit();
    if (!this.isFormValid()) {
      return;
    }
    this.doneEvent.emit(this.service);
  }

  isFormValid() {
    return this.form.valid && this.isServiceValid;
  }

  onFormSubmit() {
    this.validateForm();
  }

  validateForm() {
    this.isServiceValid = true;
    if (!this.service.name) {
      this.isServiceValid = false;
    }
  }

  getAvailableServices(services: string[]) {
    const servicesToExclude = this.existingServices.map(service => service.name);
    return services.filter(service => !servicesToExclude.includes(service));
  }

  buildDataForServiceSelector(services: string[]) {
    let data = [];
    for (let service of services) {
      data.push({
        text: service,
        value: service
      })
    }
    return data;
  }

  getMileageFrequencyOptions() {
    return MILEAGE_FREQUENCY_OPTIONS;
  }

  getTemporalUnits() {
    return TemporalUnit.toArray();
  }

  formatNumber(number: number) {
    return StringUtils.formatNumber(number);
  }
  
  arrayOfNumbers(size: number) {
    return Utils.arrayOfNumbers(size);
  }

}
