import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import Utils from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class RouteHistory {
  private history: string[] = [];

  constructor(
    private router: Router
  ) { }

  recordHistory(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  getHistory(): string[] {
    return Utils.removeConsecutiveDuplicates(this.history);
  }

  getPreviousUrl(): string {
    const history = this.getHistory();
    return history[history.length - 2];
  }
}
