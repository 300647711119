export default class ApiRequestHelper {
    static cleanJsonPayloadArray(array: any[]) {
        for (var item of array) {
          this.cleanJsonPayloadObject(item);
        }
    }
    
    static cleanJsonPayloadObject(object: any) {
        Object.keys(object).forEach((key, index) => {
          if (!object[key]) {
            object[key] = undefined;
          }
        });
    }
    
    static getIdsFromObjects(array: any[]): string[] {
        let ids = [];
        for (var item of array) {
          if (item.hasOwnProperty('id')) {
            ids.push(item['id']);
          }
        }
        return ids;
    }
}