import { TemporalUnit } from 'src/app/models/TemporalUnit';
import { AlertDueInPriority } from './AlertDueInPriority';
import { AlertType } from './AlertType';
import { Urgency } from './Urgency';

export class Alert {
    id: string;
    type: AlertType
    message: string;
    urgency: Urgency;
    dueInPriority: AlertDueInPriority
    dueInMilesEnabled: boolean;
    dueInMiles: number;
    dueInTimeEnabled: boolean;
    dueInTime: number;
    temporalUnit: TemporalUnit
}