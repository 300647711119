import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, TemplateRef, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import Quill from "quill";

import { RegistrationLog } from 'src/app/models/RegistrationLog';
import StringUtils from 'src/app/utils/string-utils';
import DateUtils from 'src/app/utils/date-utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { FileService } from 'src/app/services/file.service';
import { states } from 'src/app/variables/states'

@Component({
  selector: 'app-add-registration-log-form',
  templateUrl: './add-registration-log.component.html',
  styleUrls: ['./add-registration-log.component.scss']
})
export class AddRegistrationLogComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() log: RegistrationLog = new RegistrationLog();
  @Input() viewOnly: boolean = false;
  @Input() editMode: boolean = false;
  @Output() doneEvent = new EventEmitter<RegistrationLog>();
  @ViewChild('addRegistrationLogForm') form: NgForm;
  @ViewChild('cost') costInput: ElementRef;
  private notesTextEditor: Quill;
  private uploadFilesModal: BsModalRef;
  private isNotesTextEditorEnabled: boolean = false;
  private states: string[];

  constructor(
    private fileService: FileService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.states = states;

    if (this.log.notes) {
      this.initNotesTextEditor();
    }

    this.setDefaults();
  }

  ngAfterViewInit() {
    if (this.log.date) {
      this.log.date = StringUtils.formatDateForInput(this.log.date);
    }
    if (this.log.cost) {
      this.costInput.nativeElement.value = StringUtils.formatCurrencyWithDefaultDecimal(this.log.cost);
    }
    if (this.log.emissionsCompletedDate) {
      this.log.emissionsCompletedDate = StringUtils.formatDateForInput(this.log.emissionsCompletedDate);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const viewOnlyChange = changes['viewOnly'];
    if (viewOnlyChange) {
      if (viewOnlyChange.currentValue) {
        this.disableNotesTextEditor();
      } else {
        this.enableNotesTextEditor();
      }
    }
  }

  setDefaults() {
    if (!this.log.interval) {
      this.log.interval = 1;
    }
  }

  initNotesTextEditor() {
    this.isNotesTextEditorEnabled = true;
    this.notesTextEditor = new Quill("#notesTextEditor", {
      modules: {
        toolbar: [
          ["bold", "italic"],
          [
            {
              list: "ordered"
            },
            {
              list: "bullet"
            }
          ]
        ]
      },
      theme: "snow"
    });
    const delta = this.notesTextEditor.clipboard.convert(this.log.notes);
    this.notesTextEditor.setContents(delta);
    this.setHandlersForNotesTextEditor();
    if (this.viewOnly) {
      this.notesTextEditor.disable();
    }
  }

  setHandlersForNotesTextEditor() {
    this.notesTextEditor.on('text-change', (delta, oldDelta, source) => {
      const textLimit = 1000;
      if (this.notesTextEditor.getLength() > textLimit) {
        this.notesTextEditor.deleteText(textLimit, this.notesTextEditor.getLength());
      }
      this.log.notes = this.notesTextEditor.root.innerHTML;
    });
  }

  enableNotesTextEditor() {
    this.notesTextEditor.enable();
  }

  disableNotesTextEditor() {
    this.notesTextEditor.disable();
  }

  onChangeDate(element) {
    if (!DateUtils.isValid(this.log.date) || !DateUtils.isInPast(moment(this.log.date))) {
      element.target.value = null;
    }
  }

  onChangeEmissionsCompletedDate(element) {
    if (!DateUtils.isValid(this.log.date) || !DateUtils.isInPast(moment(this.log.date))) {
      element.target.value = null;
    }
  }

  onChangeCost() {
    this.costInput.nativeElement.value = StringUtils.formatCurrencyWithDefaultDecimal(this.costInput.nativeElement.value);
    this.log.cost = StringUtils.formatCurrencyAsNumber(StringUtils.formatCurrencyWithDefaultDecimal(this.costInput.nativeElement.value));
  }

  onClickEmissionsCompletedCheckbox() {
    if (!this.log.emissionsCompleted) {
      this.log.emissionsCompletedDate = undefined;
    }
  }

  onClickTodayLink() {
    this.form.controls['date'].setValue(moment().format("YYYY-MM-DD"));
  }

  onClickAddReceiptLink(modal: TemplateRef<any>) {
    this.uploadFilesModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseUploadFilesModal() {
    this.closeUploadFilesModal();
  }

  onDoneUploadingFiles(files: UploadedFile[]) {
    this.closeUploadFilesModal();
    if (files && files.length > 0) {
      this.log.files.push(...files);
    }
  }

  closeUploadFilesModal() {
    this.uploadFilesModal.hide();
  }

  onClickDeleteFile(file: UploadedFile) {
    this.log.files = this.log.files.filter(f => f.id !== file.id);
    if (!this.editMode) {
      this.fileService.deleteFile(file.id).subscribe(() => {});
    }
  }

  onClickAddNotesLink() {
    this.initNotesTextEditor();
  }

  onClickDone() {
    this.onFormSubmit();
    if (!this.isFormValid()) {
      return;
    }
    this.doneEvent.emit(this.log);
  }

  onFormSubmit() {
    this.form.control.markAllAsTouched();
  }

  isFormValid() {
    return this.form.valid;
  }

}
