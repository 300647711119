import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagInputModule } from 'ngx-chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportIssueComponent } from './report-issue/report-issue.component';
import { SuggestFeatureComponent } from './suggest-feature/suggest-feature.component';

@NgModule({
  imports: [
    CommonModule,
    TagInputModule,
    FormsModule
  ],
  declarations: [
    ReportIssueComponent,
    SuggestFeatureComponent
  ],
  exports: [
    ReportIssueComponent,
    SuggestFeatureComponent
  ]
})
export class SharedModalsModule { }
