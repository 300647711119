import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, throwError } from "rxjs";
import { Router } from '@angular/router';
import { catchError } from "rxjs/operators";

import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const jwt = localStorage.getItem("jwt");
        if (jwt) {
            request = request.clone({
                headers: request.headers.set("Authorization", "Bearer " + jwt)
            });
        }
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
              if (err.status == 401) {
                this.authService.logout();
                this.router.navigate(['/login'], { queryParams: { returnUrl: request.url } });
                return EMPTY;
              } else {
                return throwError(err);
              }
            })
        );
    }
}