import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { SpinnerSharedService } from "../services/shared/spinner.shared.service";

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  numberOfOpenRequests: number = 0;

  constructor(private sharedSpinnerService: SpinnerSharedService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.has('X-Skip-Spinner')) {
      this.numberOfOpenRequests++;
      this.sharedSpinnerService.show();
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (!request.headers.has('X-Skip-Spinner')) {
          this.numberOfOpenRequests--;
          if (this.numberOfOpenRequests === 0) {
            this.sharedSpinnerService.hide();
          }
        }
      })
    );
  }
}