import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class AvailablePartsService {
  private getPartsUrl = environment.apiUrl + '/available-parts/get';

  constructor(private http: HttpClient) { }

  getParts(): Observable<string[]> {
    return this.http.post<string[]>(this.getPartsUrl, null, httpOptions);
  }
}
