import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from "rxjs/operators";

import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { UserVehicleSharedService } from 'src/app/services/shared/user-vehicle.shared.service';
import { UserVehicle } from 'src/app/models/UserVehicle';
import { UserVehicleService } from 'src/app/services/user-vehicle.service';
import { AuthSharedService } from "src/app/services/shared/auth.shared.service";
import Utils from 'src/app/utils/utils';
import { SidebarSharedService } from "src/app/services/shared/sidebar.shared.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  private isGarageActive: boolean = false;
  private isSettingsActive: boolean = false;
  private vehicleDropdownInFocus: boolean = false;
  private vehicleDropdownOpen: boolean = false;
  private sidenavOpen: boolean = true;
  private loggedInUser: User;
  private currentVehicle: UserVehicle;
  private userVehicles: UserVehicle[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private userVehicleService: UserVehicleService,
    private userVehicleSharedService: UserVehicleSharedService,
    private authSharedService: AuthSharedService,
    private sidebarSharedService: SidebarSharedService
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.isGarageActive = urlAfterRedirects.includes('/garage');
        this.isSettingsActive = urlAfterRedirects.includes('/settings');
      });
  }

  ngOnInit() {
    if (this.isMobile()) {
      this.toggleSidenav();
    }
    this.loggedInUser = this.authService.getLoggedInUser();
    this.initEventListeners();
    this.userVehicles = this.getEnabledVehicles(this.userVehicleService.getUserVehiclesFromLocalStorage());
    this.currentVehicle = Utils.getByIdFromArray(this.userVehicles, this.userVehicleService.getCurrentVehicle().id);
  }

  initEventListeners() {
    this.userVehicleSharedService.setCurrentVehicleEvent().subscribe((userVehicle: UserVehicle) => {
      if (userVehicle) {
        this.currentVehicle = Utils.getByIdFromArray(this.userVehicles, userVehicle.id);
      } else {
        this.currentVehicle = null;
      }
    });
    this.userVehicleSharedService.setUserVehiclesEvent().subscribe((userVehicles: UserVehicle[]) => {
      this.userVehicles = this.getEnabledVehicles(userVehicles);
    });
    this.authSharedService.setLoggedInUserEvent().subscribe((loggedInUser) => {
      this.loggedInUser = loggedInUser;
    });
    this.sidebarSharedService.selectMenuItemEvent().subscribe(() => {
      if (this.isMobile()) {
        this.toggleSidenav();
      }
    });
  }
  
  onChangeSelectedVehicle() {
    this.userVehicleService.setCurrentVehicle(this.currentVehicle);
    if (this.router.url.match('/dashboard*')) {
      this.reloadComponent();
    } else {
      this.router.navigate(['/dashboard']);
    }
    this.closeVehicleDropdown();
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/same-route']);
  }

  logout() {
    this.authService.logout();
  }

  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }

  openVehicleDropdown() {
    this.vehicleDropdownOpen = true;
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(() => {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(() => {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  }

  closeVehicleDropdown() {
    this.vehicleDropdownOpen = false;
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  }

  isMobile() {
    return window.innerWidth <= 767;
  }

  getEnabledVehicles(userVehicles: UserVehicle[]) {
    return userVehicles.filter(userVehicle => userVehicle.enabled);
  }
}
