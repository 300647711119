import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Alert } from 'src/app/models/Alert';
import { Urgency } from 'src/app/models/Urgency';
import { AlertType } from 'src/app/models/AlertType';
import { AlertService } from 'src/app/services/alert.service';
import { Router } from '@angular/router';
import { AlertsService } from 'src/app/services/alerts.service';

const OVERDUE: string  = "Overdue";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() alert: Alert;
  @Output() dismissEvent = new EventEmitter<Alert>();
  private isOverdue: boolean = false;
  private dueInText: string;

  constructor(
    private alertsService: AlertsService,
    private alertService: AlertService,
    private router: Router
    ) { }

  ngOnInit() {
    this.dueInText = this.alertsService.getDueInText(this.alert);
    this.isOverdue = this.dueInText === OVERDUE;
  }

  onClickCompleted() {
    if (this.alert.type === AlertType.SERVICE) {
      this.alertService.showQuestion("Do you want to add a service log?", null, "Yes, let's do it!", "No, maybe later")
      .then((result) => {
        if (result.value) { // Clicked "Yes"
          this.router.navigate(['/logs/service/add']);
        } else { // Clicked "No"
          this.dismiss();
        }
      });
    } else {
      this.dismiss();
    }
  }

  onClickDismiss() {
    this.dismiss();
  }

  dismiss() {
    this.dismissEvent.emit(this.alert);
  }

  isUrgent() {
    return this.alert.urgency === Urgency.HIGH;
  }

  getTypeDisplayText() {
    const type = this.alert.type;
    if (type === AlertType.SERVICE) {
      return "Services";
    } else if (type === AlertType.REMINDER) {
      return "Reminders";
    }
  }

}
