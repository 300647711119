import { TemporalUnit } from "src/app/models/TemporalUnit";

export class Part {
    id: string
    type: string;
    name: string;
    number: string;
    cost: string;
    quantity: number;
    hasWarranty: boolean;
    warrantyTerm: number;
    warrantyTemporalUnit: TemporalUnit;
    notes: string;
}