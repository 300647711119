import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  private getMakesUrl = environment.apiUrl + '/vehicles/getMakes';
  private getModelsUrl = environment.apiUrl + '/vehicles/getModels';
  private getYearsUrl = environment.apiUrl + '/vehicles/getYears';

  constructor(
    private http: HttpClient
  ) { }

  getMakes(): Observable<string[]> {
    return this.http.post<string[]>(this.getMakesUrl, null, httpOptions);
  }

  getModels(make: string): Observable<string[]> {
    const body = {
      make
    }
    return this.http.post<string[]>(this.getModelsUrl, body, httpOptions);
  }

  getYears(make: string, model: string): Observable<string[]> {
    const body = {
      make,
      model
    }
    return this.http.post<string[]>(this.getYearsUrl, body, httpOptions);
  }
}
