import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import Chart from "chart.js";

import { LineChart } from 'src/app/models/LineChart';
import { chartOptions, parseOptions, buildOptions, buildLineChartData } from "src/app/variables/charts";

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit, OnChanges {
  @Input() lineChart: LineChart;
  private chart;

  constructor() { }

  ngOnInit() {
    parseOptions(Chart, chartOptions());
    this.buildChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lineChart && this.chart) {
      this.updateChart();
    }
  }

  buildChart() {
    const lineChartElement = document.getElementById('line-chart');
    this.chart = new Chart(lineChartElement, {
      type: 'line',
      options: buildOptions(),
      data: this.buildChartData()
    });
  }

  updateChart() {
    this.chart.options = buildOptions();
    this.chart.data = this.buildChartData();
    this.chart.update();
  }

  buildChartData() {
    return buildLineChartData(
      this.lineChart.xAxisLabels,
      this.lineChart.lineHoverLabel,
      this.lineChart.data
    );
  }

}
