import { Component, OnInit } from '@angular/core';

import { RegistrationLogService } from  'src/app/services/registration-log.service'
import { RegistrationLog } from 'src/app/models/RegistrationLog'
import { TableHeader } from 'src/app/models/tables/TableHeader';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';

const REGISTRATION_LOG_TABLE_HEADERS: TableHeader[] = [
  { name: "Date", property: "date" },
  { name: "State", property: "state" },
  { name: "Cost", property: "cost" },
  { name: "Emissions Completed", property: "emissionsCompleted" }
];

@Component({
  selector: 'app-registration-logs-splash',
  templateUrl: './registration-logs-splash.component.html',
  styleUrls: ['./registration-logs-splash.component.scss']
})
export class RegistrationLogsSplashComponent implements OnInit {
  private registrationLogTableHeaders: TableHeader[];
  private logs: RegistrationLog[];
  private dataLoaded: boolean = false;

  constructor(
    private registrationLogService: RegistrationLogService,
    private router: Router,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.registrationLogTableHeaders = REGISTRATION_LOG_TABLE_HEADERS;
    this.registrationLogService.getLogs().subscribe(logs => {
      this.logs = logs;
      this.dataLoaded = true;
    });
  }

  onClickLog(log: RegistrationLog) {
    this.router.navigate(['/logs/registration/edit', log.id]);
  }

}
