import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SuggestFeature } from 'src/app/models/forms/SuggestFeature';

@Component({
  selector: 'app-suggest-feature-modal',
  templateUrl: './suggest-feature.component.html',
  styleUrls: ['./suggest-feature.component.scss']
})
export class SuggestFeatureComponent implements OnInit {
  @Input() suggestFeature: SuggestFeature = new SuggestFeature();
  @Output() closeEvent = new EventEmitter<any>();
  @Output() doneEvent = new EventEmitter<SuggestFeature>();

  constructor() { }

  ngOnInit() {}

  onClickClose() {
    this.closeEvent.emit();
  }

  onClickDone() {
    if (!this.suggestFeature.description) {
      this.closeEvent.emit();
    }
    this.doneEvent.emit(this.suggestFeature);
  }

}
