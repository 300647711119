import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { IncidentLog } from 'src/app/models/IncidentLog';
import { UserVehicleService } from 'src/app/services/user-vehicle.service';
import DateUtils from 'src/app/utils/date-utils';
import ApiRequestHelper from '../utils/api-request-helper';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class IncidentLogService {
  private getLogUrl = environment.apiUrl + '/user/vehicle/logs/getById';
  private getLogsUrl = environment.apiUrl + '/user/vehicle/logs/get';
  private addUrl = environment.apiUrl + '/user/vehicle/logs/add';
  private updateUrl = environment.apiUrl + '/user/vehicle/logs/update';
  private deleteUrl = environment.apiUrl + '/user/vehicle/logs/delete';

  constructor(
    private http: HttpClient,
    private userVehicleService: UserVehicleService
  ) { }

  getLog(logId: string): Observable<IncidentLog> {
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      logId
    }
    return this.http.post<IncidentLog>(this.getLogUrl, body, httpOptions).pipe(
      map(log => {
        log.date = new Date(log.date);
        return log;
      })
    );
  }

  getLogs(): Observable<IncidentLog[]> {
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      type: "INCIDENT"
    }
    return this.http.post<IncidentLog[]>(this.getLogsUrl, body, httpOptions).pipe(
      map(logs => {
        for (const log of logs) {
          log.date = new Date(log.date);
        }
        return logs;
      })
    );
  }

  addLog(incidentLog: IncidentLog) {
    let log: any = incidentLog;
    if (incidentLog.date) {
      log.date = DateUtils.toIsoTimestamp(incidentLog.date);
    }
    if (incidentLog.files) {
      log.files = ApiRequestHelper.getIdsFromObjects(incidentLog.files);
    }
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      type: "INCIDENT",
      data: log
    }
    return this.http.post<any>(this.addUrl, body, httpOptions);
  }

  updateLog(incidentLog: IncidentLog) {
    let log: any = incidentLog;
    if (incidentLog.date) {
      log.date = DateUtils.toIsoTimestamp(incidentLog.date);
    }
    if (incidentLog.files) {
      log.files = ApiRequestHelper.getIdsFromObjects(incidentLog.files);
    }
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      logId: incidentLog.id,
      data: log
    }
    return this.http.post<any>(this.updateUrl, body, httpOptions);
  }

  deleteLog(logId: string) {
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      logId
    }
    return this.http.post<any>(this.deleteUrl, body, httpOptions);
  }
}
