import { Component, OnInit } from '@angular/core';

import { ReleaseNote } from 'src/app/models/ReleaseNote';
import { ReleaseNoteChange } from 'src/app/models/ReleaseNoteChange';
import { ReleaseNoteChangeType } from 'src/app/models/ReleaseNoteChangeType';
import { ReleaseNotesService } from 'src/app/services/release-notes.service';
import StringUtils from 'src/app/utils/string-utils';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {
  private releaseNotes: ReleaseNote[] = [];

  constructor(private releaseNotesService: ReleaseNotesService) { }

  ngOnInit() {
    this.releaseNotesService.getReleaseNotes().subscribe(releaseNotes => {
      this.releaseNotes = releaseNotes;
    });
  }

  getTypeDisplayText(releaseNoteChange: ReleaseNoteChange) {
    const type = releaseNoteChange.type;
    if (type === ReleaseNoteChangeType.FEATURE) {
      return "Feature";
    } else if (type === ReleaseNoteChangeType.UPDATE) {
      return "Update";
    } else if (type === ReleaseNoteChangeType.BUG_FIX) {
      return "Bug fix";
    }
  }

  formatDateAsPrintedMonthDayYear(date: any) {
    return StringUtils.formatDateAsPrintedMonthDayYear(date);
  }
}
