import { AfterViewInit, EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { SortDirection } from '@swimlane/ngx-datatable';

import { TableHeader } from 'src/app/models/tables/TableHeader';
import { AlertService } from 'src/app/services/alert.service';
import StringUtils from 'src/app/utils/string-utils';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() tableHeaders: TableHeader[];
  @Input() data: any[];
  @Input() addRecordRouterLink: string;
  @Output() selectRowEvent = new EventEmitter<any>();
  private rows: any[] = [];
  private entriesPerPage: number = 10;
  private defaultSorts: any[] = [];
  private datatableFooterHeight: number = 50;
 
  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.rows = this.data;
    this.sortByDateByDefault();
  }

  ngAfterViewInit() {
    const datatablePageCount = document.querySelector('.page-count') as HTMLElement;
    if (window.innerWidth <= 767) {
      if (datatablePageCount) {
        datatablePageCount.style.display = 'none';
        this.datatableFooterHeight = 0;
      }
    } else {
      this.datatableFooterHeight = 50;
    }
  }

  sortByDateByDefault() {
    const firstColumn = this.tableHeaders[0].property;
    if (firstColumn.toLowerCase().includes("date")) {
      this.defaultSorts = [{ prop: firstColumn, dir: SortDirection.desc }];
    }
  }

  formatCell(value: any) {
    if (value instanceof Date) {
      return StringUtils.formatDate(value);
    } else if (StringUtils.isCurrency(value.toString())) {
      return StringUtils.formatCurrency(value, true);
    } else if (Array.isArray(value) && value.length > 1 && typeof value[0] === "string") {
      return value.join(", ");
    }
    return value;
  }

  onChangeEntriesPerPage($event) {
    this.entriesPerPage = $event.target.value;
  }

  onSearch($event) {
    const searchTerm = $event.target.value;
    this.rows = this.data.filter((row) => {
      for (let column in row) {
        const value = String(this.formatCell(row[column])).toLowerCase();
        if (value.includes(searchTerm)) {
          return true;
        }
      }
      return false;
    });
  }

  onClickRow(event) {
    if (event.type == 'click') {
      this.selectRowEvent.emit(event.row);
    }
  }

  rowClass = () => {
    return {
      'table-row': true
    };
  }

}
