import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import Selectr from 'mobius1-selectr';

@Component({
  selector: 'app-add-custom-services',
  templateUrl: './add-custom-services.component.html',
  styleUrls: ['./add-custom-services.component.scss']
})
export class AddCustomServicesComponent implements OnInit, AfterViewInit {
  @Output() doneEvent = new EventEmitter<string[]>();
  private customServicesSelector: Selectr;
  private services: string[] = [];

  constructor() { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.initCustomServicesSelector();
  }

  initCustomServicesSelector() {
    this.customServicesSelector = new Selectr(
      document.getElementById("customServicesSelector") as HTMLSelectElement, 
      {
        multiple: true,
        maxSelections: 5,
        placeholder: "",
        tagPlaceholder: 'Enter services...',
        closeOnScroll: true,
        taggable: true,
        messages: {
          maxSelections: "A maximum of {max} services can be added."
        },
        data: []
      }
    );
    this.setHandlersForCustomServicesSelector();
  }

  setHandlersForCustomServicesSelector() {
    this.customServicesSelector.on('selectr.select', (selector) => {
      this.services.push(selector.value);
    });

    this.customServicesSelector.on('selectr.deselect', (selector) => {
      this.services = this.services.filter(s => s !== selector.value);
    });
  }

  onClickDone() {
    this.doneEvent.emit(this.services);
  }

}
