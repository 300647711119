import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { FileUploadStatus } from 'src/app/models/files/FileUploadStatus';
import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { UploadStatus } from 'src/app/models/files/UploadStatus';

@Component({
  selector: 'app-upload-files-form',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {
  @Input() files: UploadedFile[];
  @Input() editMode: boolean = false;
  @Output() doneEvent = new EventEmitter<UploadedFile[]>();
  @Output() backEvent = new EventEmitter<any>();
  private fileUploadStatus: FileUploadStatus;
  private uploadFilesModal: BsModalRef;

  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  onClickBack() {
    this.backEvent.emit();
  }

  onClickDone() {
    let uploadedFiles: UploadedFile[] = [];
    if (this.fileUploadStatus) {
      uploadedFiles = this.fileUploadStatus.uploadedFiles
        .map(uploadedFile => uploadedFile.uploadedFile);
    }
    this.doneEvent.emit(uploadedFiles);
  }

  onClickDoneEditingFiles() {
    this.doneEvent.emit(this.files);
  }

  onClickDeleteFileWhileInEditMode(file: UploadedFile) {
    this.files = this.files.filter(f => f.originalName === file.originalName);
  }

  onClickUploadWhileInEditMode(modal: TemplateRef<any>) {
    this.uploadFilesModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseUploadFilesModal() {
    this.closeUploadFilesModal();
  }

  onDoneUploadingFilesUsingModal(files: UploadedFile[]) {
    this.closeUploadFilesModal();
    if (files && files.length > 0) {
      this.files.push(...files);
    }
  }

  closeUploadFilesModal() {
    this.uploadFilesModal.hide();
  }

  onUploadStatusChange(fileUploadStatus: FileUploadStatus) {
    this.fileUploadStatus = fileUploadStatus;
  }

  isUploadInProgress(): boolean {
    if (!this.fileUploadStatus || (this.fileUploadStatus && !this.fileUploadStatus.status)) {
      return false;
    }
    return this.fileUploadStatus.status === UploadStatus.IN_PROGRESS;
  }

}
