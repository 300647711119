import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ResetPassword } from 'src/app/models/forms/ResetPassword';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';

const DEFAULT_ERROR_MESSAGE: string = "Something went wrong";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('resetForgottenPasswordForm') form: NgForm;
  private resetPassword: ResetPassword = new ResetPassword();
  private error: boolean = false;
  private errorMessage: string = DEFAULT_ERROR_MESSAGE;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onClickUpdate() {
    this.activatedRoute.queryParams.subscribe(
      (queryParams: Params) => {
        this.userService.resetPassword(queryParams.token, this.resetPassword.newPassword).subscribe(() => {
          this.router.navigate(['/login']);
          this.alertService.showSuccess("Your password has been updated.", null, "Thanks!");
        },
        (err) => {
          this.error = true;
        });
      }
    );
  }

}
