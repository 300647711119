import { Injectable } from '@angular/core';
import swal from "sweetalert2";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  showSuccess(message: string) {
    swal.fire({
      title: message,
      type: 'success',
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 2000
    });
  }

  showSomethingWentWrong() {
    swal.fire({
      title: 'Something went wrong. Please try again later.',
      type: 'error',
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 2000
    });
  }
}