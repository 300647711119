import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ServiceLog } from 'src/app/models/ServiceLog';
import { UserVehicleService } from 'src/app/services/user-vehicle.service';
import ApiRequestHelper from 'src/app/utils/api-request-helper';
import DateUtils from 'src/app/utils/date-utils';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class ServiceLogService {
  private getLogUrl = environment.apiUrl + '/user/vehicle/logs/getById';
  private getLogsUrl = environment.apiUrl + '/user/vehicle/logs/get';
  private addUrl = environment.apiUrl + '/user/vehicle/logs/add';
  private updateUrl = environment.apiUrl + '/user/vehicle/logs/update';
  private deleteUrl = environment.apiUrl + '/user/vehicle/logs/delete';

  constructor(
    private http: HttpClient,
    private userVehicleService: UserVehicleService
  ) { }

  getLog(logId: string): Observable<ServiceLog> {
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      logId
    }
    return this.http.post<ServiceLog>(this.getLogUrl, body, httpOptions).pipe(
      map(log => {
        log.date = new Date(log.date);
        return log;
      })
    );
  }

  getLogs(): Observable<ServiceLog[]> {
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      type: "SERVICE"
    }
    return this.http.post<ServiceLog[]>(this.getLogsUrl, body, httpOptions).pipe(
      map(logs => {
        for (const log of logs) {
          log.date = new Date(log.date);
        }
        return logs;
      })
    );
  }

  addLog(serviceLog: ServiceLog) {
    let log: any = serviceLog;
    if (serviceLog.files) {
      log.files = ApiRequestHelper.getIdsFromObjects(serviceLog.files);
    }
    if (serviceLog.date) {
      log.date = DateUtils.toIsoTimestamp(serviceLog.date);
    }
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      type: "SERVICE",
      data: log
    }
    return this.http.post<any>(this.addUrl, body, httpOptions);
  }

  updateLog(serviceLog: ServiceLog) {
    let log: any = serviceLog;
    if (serviceLog.files) {
      log.files = ApiRequestHelper.getIdsFromObjects(serviceLog.files);
    }
    if (serviceLog.date) {
      log.date = DateUtils.toIsoTimestamp(serviceLog.date);
    }
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      logId: serviceLog.id,
      data: log
    }
    return this.http.post<any>(this.updateUrl, body, httpOptions);
  }

  deleteLog(logId: string) {
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id,
      logId
    }
    return this.http.post<any>(this.deleteUrl, body, httpOptions);
  }
}
