export class Tag {
    display: string;
    value: string;
    readonly: boolean;
    constructor(
        display: string,
        value: string,
        readonly?: boolean
        ) {
        this.display = display;
        this.value = value;
        this.readonly = readonly;
    }
}