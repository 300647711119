import { UploadedFile } from 'src/app/models/files/UploadedFile';

export class RegistrationLog {
    id: string;
    date: any;
    state: string;
    cost: string;
    interval: number;
    emissionsCompleted: boolean;
    emissionsCompletedDate: any;
    notes: string;
    files: UploadedFile[] = [];
}