import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

import { GasLog } from 'src/app/models/GasLog';
import StringUtils from 'src/app/utils/string-utils';
import DateUtils from 'src/app/utils/date-utils';

@Component({
  selector: 'app-add-gas-log-form',
  templateUrl: './add-gas-log.component.html',
  styleUrls: ['./add-gas-log.component.scss']
})
export class AddGasLogComponent implements OnInit, AfterViewInit {
  @Input() log: GasLog = new GasLog();
  @Input() viewOnly: boolean = false;
  @Input() editMode: boolean = false;
  @Output() doneEvent = new EventEmitter<GasLog>();
  @ViewChild('addGasLogForm') form: NgForm;
  @ViewChild('costPerGallon') costPerGallonInput: ElementRef;
  @ViewChild('numberOfGallons') numberOfGallonsInput: ElementRef;
  @ViewChild('totalCost') totalCostInput: ElementRef;
  @ViewChild('mileage') mileageInput: ElementRef;
  private isMileageEnabled: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.log.date) {
      this.log.date = StringUtils.formatDateForInput(this.log.date);
    }
    if (this.log.costPerGallon) {
      this.costPerGallonInput.nativeElement.value = StringUtils.formatCurrencyWithExtendedDefaultDecimal(this.log.costPerGallon);
    }
    if (this.log.costPerGallon) {
      this.numberOfGallonsInput.nativeElement.value = StringUtils.formatCurrencyWithExtendedDefaultDecimal(this.log.numberOfGallons);
    }
    if (this.log.mileage) {
      this.mileageInput.nativeElement.value = StringUtils.formatNumber(this.log.mileage);
    }
    this.updateTotalCost();
  }

  onChangeDate(element) {
    if (!DateUtils.isValid(this.log.date) || !DateUtils.isInPast(moment(this.log.date))) {
      element.target.value = null;
    }
  }

  onChangeCostPerGallon() {
    this.costPerGallonInput.nativeElement.value = StringUtils.formatCurrencyWithExtendedDefaultDecimal(this.costPerGallonInput.nativeElement.value);
    this.log.costPerGallon = StringUtils.formatCurrencyAsNumber(StringUtils.formatCurrencyWithExtendedDefaultDecimal(this.costPerGallonInput.nativeElement.value));
    this.updateTotalCost();
  }

  onChangeNumberOfGallons() {
    this.numberOfGallonsInput.nativeElement.value = StringUtils.formatCurrencyWithExtendedDefaultDecimal(this.numberOfGallonsInput.nativeElement.value);
    this.log.numberOfGallons = StringUtils.formatCurrencyAsNumber(StringUtils.formatCurrencyWithExtendedDefaultDecimal(this.numberOfGallonsInput.nativeElement.value));
    this.updateTotalCost();
  }

  updateTotalCost() {
    if (this.log.costPerGallon && this.log.numberOfGallons) {
      const totalCost = parseFloat(this.log.costPerGallon) * parseFloat(this.log.numberOfGallons)
      this.totalCostInput.nativeElement.value = totalCost.toFixed(2);
    }
  }

  onChangeMileage() {
    const formattedMileage: any = StringUtils.formatNumber(this.mileageInput.nativeElement.value);
    this.mileageInput.nativeElement.value = formattedMileage;
    this.log.mileage = formattedMileage.replace(",", "");
  }

  onClickTodayLink() {
    this.form.controls['date'].setValue(moment().format("YYYY-MM-DD"));
  }

  onClickNotSureLink() {
    this.isMileageEnabled = false;
  }

  onClickDone() {
    this.onFormSubmit();
    if (!this.isFormValid()) {
      return;
    }
    this.doneEvent.emit(this.log);
  }

  onFormSubmit() {
    this.form.control.markAllAsTouched();
  }

  isFormValid() {
    return this.form.valid;
  }

}
