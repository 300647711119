import { UploadStatus } from 'src/app/models/files/UploadStatus';
import { UploadedFile } from 'src/app/models/files/UploadedFile';

export class FileUpload {
    file: File;
    status: UploadStatus;
    uploadedFile: UploadedFile;
    constructor(file: File, status: UploadStatus) {
        this.file = file;
        this.status = status;
    }
}