import { SharedUserBuilder } from 'src/app/models/builders/SharedUserBuilder';

export class SharedUser {
    owner: boolean;
    id: string;
    name: string;
    email: string;

    constructor(sharedUserBuilder: SharedUserBuilder) {
        this.owner = sharedUserBuilder._owner;
        this.id = sharedUserBuilder._id;
        this.name = sharedUserBuilder._name;
        this.email = sharedUserBuilder._email;
    }
}