import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UpdatePassword } from 'src/app/models/forms/UpdatePassword';
import { NotificationSettings } from 'src/app/models/NotificationSettings';
import { UserPreference } from '../models/UserPreference';
import { UserPreferences } from '../models/UserPreferences';
import { AccountSettings } from '../models/AccountSettings';
import { UserSession } from '../models/UserSession';
import { tap, shareReplay } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Subscription } from '../models/Subscription';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

const skipSpinnerHttpOptions = {
  headers: httpOptions.headers.set('X-Skip-Spinner', 'true')
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private registerUrl = environment.apiUrl + '/user/register';
  private getSubscriptionUrl = environment.apiUrl + '/user/getSubscription';
  private updateNameUrl = environment.apiUrl + '/user/updateName';
  private updateNotificationSettingsUrl = environment.apiUrl + '/user/updateNotificationSettings';
  private updatePreferencesUrl = environment.apiUrl + '/user/updatePreferences';
  private updatePreferenceUrl = environment.apiUrl + '/user/updatePreference';
  private verifyEmailUrl = environment.apiUrl + '/user/verifyEmail';
  private updatePasswordUrl = environment.apiUrl + '/user/updatePassword';
  private forgotPasswordUrl = environment.apiUrl + '/user/forgotPassword';
  private resetPasswordUrl = environment.apiUrl + '/user/resetPassword';
  private contactUrl = environment.apiUrl + '/user/contact';
  private getAccountSettingsUrl = environment.apiUrl + '/user/getAccountSettings';

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  register(name: string, email: string, password: string): Observable<UserSession> {
    const body = {
      name,
      email,
      password
    };
    return this.http.post<any>(this.registerUrl, body, httpOptions)
      .pipe(tap(res => this.authService.setSession(res)))
      .pipe(shareReplay(1));
  }

  getSubscription(): Observable<Subscription> {
    return this.http.post<any>(this.getSubscriptionUrl, null, skipSpinnerHttpOptions);
  }

  updateName(name: string) {
    const body = {
      name
    };
    return this.http.post<any>(this.updateNameUrl, body, httpOptions);
  }

  updateNotificationSettings(notificationSettings: NotificationSettings) {
    return this.http.post<any>(this.updateNotificationSettingsUrl, notificationSettings, httpOptions);
  }

  updatePreferences(preferences: UserPreferences) {
    return this.http.post<any>(this.updatePreferencesUrl, preferences, httpOptions);
  }

  updatePreference(preference: UserPreference, value: boolean) {
    const body = {
      preference,
      value
    }
    return this.http.post<any>(this.updatePreferenceUrl, body, httpOptions);
  }

  verifyEmail(token: string): Observable<any> {
    const body = {
      token
    }
    return this.http.post<any>(this.verifyEmailUrl, body, httpOptions);
  }

  updatePassword(updatePassword: UpdatePassword) {
    return this.http.post<any>(this.updatePasswordUrl, updatePassword, httpOptions);
  }

  forgotPassword(email: string): Observable<any> {
    const body = {
      email
    }
    return this.http.post<any>(this.forgotPasswordUrl, body, httpOptions);
  }

  resetPassword(token: string, newPassword: string): Observable<any> {
    const body = {
      token,
      newPassword
    }
    return this.http.post<any>(this.resetPasswordUrl, body, httpOptions);
  }

  contact(name: string, email: string, subject: string, message: string): Observable<any> {
    const body = {
      name,
      email,
      subject,
      message
    }
    return this.http.post<any>(this.contactUrl, body, httpOptions);
  }

  getAccountSettings(): Observable<AccountSettings> {
    return this.http.post<any>(this.getAccountSettingsUrl, null, httpOptions);
  }
}