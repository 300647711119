import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, TemplateRef, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import Selectr from 'mobius1-selectr/src/selectr';
import Quill from "quill";

import { AvailableServicesService } from 'src/app/services/available-services.service'
import { ServiceLog } from 'src/app/models/ServiceLog';
import StringUtils from 'src/app/utils/string-utils';
import DateUtils from 'src/app/utils/date-utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Part } from 'src/app/models/Part';
import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-add-service-log-form',
  templateUrl: './add-service-log.component.html',
  styleUrls: ['./add-service-log.component.scss']
})
export class AddServiceLogComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() log: ServiceLog = new ServiceLog();
  @Input() viewOnly: boolean = false;
  @Input() editMode: boolean = false;
  @Output() doneEvent = new EventEmitter<ServiceLog>();
  @ViewChild('addServiceLogForm') form: NgForm;
  @ViewChild('cost') costInput: ElementRef;
  @ViewChild('mileage') mileageInput: ElementRef;
  private completedServicesSelector: Selectr;
  private notesTextEditor: Quill;
  private addCustomServiceModal: BsModalRef;
  private addPartsModal: BsModalRef;
  private uploadFilesModal: BsModalRef;
  private isMileageEnabled: boolean = true;
  private isNotesTextEditorEnabled: boolean = false;
  private isCompletedServicesValid: boolean = true;
  private availableServices: string[] = [];
  private selectedPart: Part;

  constructor(
    private availableServicesService: AvailableServicesService,
    private fileService: FileService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.availableServicesService.getServices()
      .subscribe(services => {
        this.availableServices = services;
        const customServices = this.log.services.filter(service => !services.includes(service));
        this.initCompletedServicesSelector(customServices);
      });

    if (this.log.notes) {
      this.initNotesTextEditor();
    }
  }

  ngAfterViewInit() {
    if (this.log.date) {
      this.log.date = StringUtils.formatDateForInput(this.log.date);
    }
    if (this.log.cost) {
      this.costInput.nativeElement.value = StringUtils.formatCurrencyWithDefaultDecimal(this.log.cost);
    }
    if (this.log.mileage) {
      this.mileageInput.nativeElement.value = StringUtils.formatNumber(this.log.mileage);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const viewOnlyChange = changes['viewOnly'];
    if (viewOnlyChange) {
      if (viewOnlyChange.currentValue) {
        this.completedServicesSelector.disable();
        this.disableNotesTextEditor();
      } else {
        this.completedServicesSelector.enable();
        this.enableNotesTextEditor();
      }
    }
  }

  initCompletedServicesSelector(customServices: string[]) {
    this.completedServicesSelector = new Selectr(
      document.getElementById("completedServicesSelector") as HTMLSelectElement, 
      {
        multiple: true,
        maxSelections: 10,
        placeholder: 'Select services...',
        clearable: this.log.services.length > 0 ? false : true,
        closeOnScroll: true,
        messages: {
          maxSelections: "A maximum of {max} services can be selected."
        },
        data: this.buildDataForCompletedServicesSelector(this.availableServices.concat(customServices))
      }
    );
    this.completedServicesSelector.setValue(this.log.services);
    this.setHandlersForCompletedServicesSelector();
    if (this.viewOnly) {
      this.completedServicesSelector.disable();
    }
  }

  setHandlersForCompletedServicesSelector() {
    this.completedServicesSelector.on('selectr.select', (selector) => {
      this.log.services.push(selector.value);
      this.validateForm();
    });

    this.completedServicesSelector.on('selectr.deselect', (selector) => {
      this.log.services = this.log.services.filter(s => s !== selector.value);
      this.validateForm();
    });
  }

  initNotesTextEditor() {
    this.isNotesTextEditorEnabled = true;
    this.notesTextEditor = new Quill("#notesTextEditor", {
      modules: {
        toolbar: [
          ["bold", "italic"],
          [
            {
              list: "ordered"
            },
            {
              list: "bullet"
            }
          ]
        ]
      },
      theme: "snow"
    });
    const delta = this.notesTextEditor.clipboard.convert(this.log.notes);
    this.notesTextEditor.setContents(delta);
    this.setHandlersForNotesTextEditor();
    if (this.viewOnly) {
      this.notesTextEditor.disable();
    }
  }

  setHandlersForNotesTextEditor() {
    this.notesTextEditor.on('text-change', (delta, oldDelta, source) => {
      const textLimit = 2500;
      if (this.notesTextEditor.getLength() > textLimit) {
        this.notesTextEditor.deleteText(textLimit, this.notesTextEditor.getLength());
      }
      this.log.notes = this.notesTextEditor.root.innerHTML;
    });
  }

  enableNotesTextEditor() {
    if (this.notesTextEditor) {
      this.notesTextEditor.enable();
    }
  }

  disableNotesTextEditor() {
    if (this.notesTextEditor) {
      this.notesTextEditor.disable();
    }
  }

  onChangeDate(element) {
    if (!DateUtils.isValid(this.log.date) || !DateUtils.isInPast(moment(this.log.date))) {
      element.target.value = null;
    }
  }

  onChangeCost() {
    this.costInput.nativeElement.value = StringUtils.formatCurrencyWithDefaultDecimal(this.costInput.nativeElement.value);
    this.log.cost = StringUtils.formatCurrencyAsNumber(StringUtils.formatCurrencyWithDefaultDecimal(this.costInput.nativeElement.value));
  }

  onChangeMileage() {
    const formattedMileage: any = StringUtils.formatNumber(this.mileageInput.nativeElement.value);
    this.mileageInput.nativeElement.value = formattedMileage;
    this.log.mileage = formattedMileage.replace(",", "");
  }

  onClickAddCustomServiceLink(modal: TemplateRef<any>) {
    this.addCustomServiceModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseAddCustomServicesModal() {
    if (this.addCustomServiceModal) {
      this.addCustomServiceModal.hide();
    }
  }

  onDoneAddingCustomServices(customServices: string[]) {
    const services = this.log.services.concat(customServices);
    this.completedServicesSelector.reset();
    this.completedServicesSelector.add(this.buildDataForCompletedServicesSelector(customServices));
    this.completedServicesSelector.setValue(services);
    this.addCustomServiceModal.hide();
  }

  onClickTodayLink() {
    this.form.controls['date'].setValue(moment().format("YYYY-MM-DD"));
  }

  onClickSelfServiceLink() {
    this.form.controls['servicedAt'].setValue('Self-service');
  }

  onClickNotSureLink() {
    this.isMileageEnabled = false;
  }

  onClickAddParts(modal: TemplateRef<any>) {
    this.selectedPart = new Part();
    this.addPartsModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseAddPartsModal() {
    this.hideAddPartsModal();
  }

  onDoneAddingPart(part: Part) {
    this.log.parts.push(part);
    this.hideAddPartsModal();
  }

  onDoneEditingPart(part: Part) {
    this.hideAddPartsModal();
  }

  onDoneSearchingForExistingParts(parts: Part[]) {
    this.log.parts.push(...parts);
    this.hideAddPartsModal();
  }

  hideAddPartsModal() {
    if (this.addPartsModal) {
      this.addPartsModal.hide();
    }
  }

  onClickViewPart(modal: TemplateRef<any>, part: Part) {
    this.selectedPart = part;
    this.addPartsModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickEditPart(modal: TemplateRef<any>, part: Part) {
    this.selectedPart = part;
    this.addPartsModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickDeletePart(part: Part) {
    this.log.parts = this.log.parts.filter(p => p.type !== part.type);
  }

  onClickAddNotesLink() {
    this.initNotesTextEditor();
  }

  onClickAddReceiptLink(modal: TemplateRef<any>) {
    this.uploadFilesModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseUploadFilesModal() {
    this.closeUploadFilesModal();
  }

  onDoneUploadingFiles(files: UploadedFile[]) {
    this.closeUploadFilesModal();
    if (files && files.length > 0) {
      this.log.files.push(...files);
    }
  }

  closeUploadFilesModal() {
    this.uploadFilesModal.hide();
  }

  onClickDeleteFile(file: UploadedFile) {
    this.log.files = this.log.files.filter(f => f.id !== file.id);
    if (!this.editMode) {
      this.fileService.deleteFile(file.id).subscribe(() => {});
    }
  }

  onClickDone() {
    this.onFormSubmit();
    if (!this.isFormValid()) {
      return;
    }
    this.doneEvent.emit(this.log);
  }

  onFormSubmit() {
    this.form.control.markAllAsTouched();
    this.validateForm();
  }

  isFormValid() {
    return this.form.valid && this.isCompletedServicesValid;
  }

  validateForm() {
    this.isCompletedServicesValid = true;
    if ((!this.log.services) ||
      (this.log.services && this.log.services.length === 0)) {
      this.isCompletedServicesValid = false;
    }
  }

  buildDataForCompletedServicesSelector(services: string[]) {
    let data = [];
    for (let service of services) {
      data.push({
        text: service,
        value: service
      })
    }
    return data;
  }

}
