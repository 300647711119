import { Component, OnInit, TemplateRef } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { filter } from "rxjs/operators";
import { User } from "src/app/models/User";

import { UserVehicle } from "src/app/models/UserVehicle";
import { ReportIssue } from "src/app/models/forms/ReportIssue";
import { SuggestFeature } from "src/app/models/forms/SuggestFeature";
import { AlertService } from "src/app/services/alert.service";
import { AuthService } from "src/app/services/auth.service";
import { SidebarSharedService } from "src/app/services/shared/sidebar.shared.service";
import { UserVehicleSharedService } from 'src/app/services/shared/user-vehicle.shared.service';
import { UserService } from "src/app/services/user.service";

export interface MenuItem {
  title: string;
  path?: string;
  icon: string;
  isCollapsed?: boolean;
  subMenuItems?: SubMenuItem[];
}

export interface SubMenuItem {
  path: string;
  title: string;
}

export const MENU_ITEMS: MenuItem[] = [
  { title: 'Dashboard', path: '/dashboard', icon: "fas fa-tachometer-alt text-orange" },
  { 
    title: 'Logs', 
    icon: "ni ni-single-copy-04 text-success",
    isCollapsed: true,
    subMenuItems: [
      { title: "Services", path: "/logs/services" },
      { title: "Parts", path: "/logs/parts" },
      { title: "Gas", path: "/logs/gas" },
      { title: "Registration", path: "/logs/registration" },
      { title: "Incidents", path: "/logs/incidents" }
    ]
  },
  // { title: 'Details', path: '/details', icon: "fas fa-car text-info" }
];

export const SECONDARY_MENU_ITEMS: MenuItem[] = [
  { title: 'Garage', path: '/garage', icon: "fas fa-warehouse text-primary" },
  {
    title: 'Settings',
    path: '/settings',
    icon: "ni ni-settings-gear-65 text-default",
    isCollapsed: true,
    subMenuItems: [
      { title: "Account", path: "/settings/account" },
      // { title: "Notifications", path: "/settings/notifications" },
      { title: "Preferences", path: "/settings/preferences" },
      { title: "Vehicle", path: "/settings/vehicle" },
      { title: "Services", path: "/settings/services" },
      // { title: "Reminders", path: "/settings/reminders" }
    ]
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  private reportIssueModal: BsModalRef;
  private suggestFeatureModal: BsModalRef;
  private menuItems: MenuItem[];
  private secondaryMenuItems: MenuItem[];
  private loggedInUser: User;
  private isPinned: boolean = true;
  private isHidden: boolean = false;
  private logoLinkUrl: string = '/garage';
  private userHasAtLeastOneVehicle: boolean = true;

  constructor(
    private router: Router,
    private sidebarSharedService: SidebarSharedService,
    private userVehicleSharedService: UserVehicleSharedService,
    private alertService: AlertService,
    private authService: AuthService,
    private modalService: BsModalService,
    private userService: UserService
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.openActiveMenuItem(urlAfterRedirects);
      });
  }

  ngOnInit() {
    this.menuItems = MENU_ITEMS;
    this.secondaryMenuItems = SECONDARY_MENU_ITEMS;
    this.loggedInUser = this.authService.getLoggedInUser();
    this.initEventListeners();
  }

  initEventListeners() {
    this.userVehicleSharedService.setUserVehiclesEvent().subscribe((userVehicles: UserVehicle[]) => {
      if (this.getEnabledVehicles(userVehicles).length === 0) {
        this.menuItems = [];
        this.logoLinkUrl = '/garage';
        this.userHasAtLeastOneVehicle = false;
      } else {
        this.menuItems = MENU_ITEMS;
        this.logoLinkUrl = '/dashboard';
        this.userHasAtLeastOneVehicle = true;
      }
    });
  }

  openActiveMenuItem(currentUrl: string) {
    const menuItems = [...MENU_ITEMS, ...SECONDARY_MENU_ITEMS]
    for (var menuItem of menuItems) {
      if (menuItem.subMenuItems) {
        for (var subMenuItem of menuItem.subMenuItems) {
          if (this.isActiveRoute(currentUrl, subMenuItem.path)) {
            menuItem.isCollapsed = false;
            return;
          }
        }
      }
    }
  }

  isActiveRoute(currentUrl: string, url: string) {
    return currentUrl === url;
  }

  onClickMenuItem() {
    this.sidebarSharedService.selectMenuItem();
  }

  onClickReportAnIssue(modal: TemplateRef<any>) {
    this.reportIssueModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseReportIssueModal() {
    if (this.reportIssueModal) {
      this.reportIssueModal.hide();
    }
  }

  onClickDoneReportingIssue(reportIssue: ReportIssue) {
    this.userService.contact(this.loggedInUser.name, this.loggedInUser.email, "Report an issue", reportIssue.description)
      .subscribe(() => {
        this.alertService.showSuccess("Sent!", "We'll get back to you as soon as we can.", "Thanks!");
      });
  }

  onClickSuggestAFeature(modal: TemplateRef<any>) {
    this.suggestFeatureModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseSuggestFeatureModal() {
    if (this.suggestFeatureModal) {
      this.suggestFeatureModal.hide();
    }
  }

  onClickDoneSuggestingFeature(suggestFeature: SuggestFeature) {
    this.userService.contact(this.loggedInUser.name, this.loggedInUser.email, "Suggest a feature", suggestFeature.description)
      .subscribe(() => {
        this.alertService.showSuccess("Sent!", "We'll get back to you as soon as we can.", "Thanks!");
      });
  }

  getEnabledVehicles(userVehicles: UserVehicle[]) {
    return userVehicles.filter(userVehicle => userVehicle.enabled);
  }
}
