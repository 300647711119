import { Component, OnInit } from '@angular/core';

import { GasLogService } from  'src/app/services/gas-log.service'
import { GasLog } from 'src/app/models/GasLog'
import { TableHeader } from 'src/app/models/tables/TableHeader';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';

const GAS_LOG_TABLE_HEADERS: TableHeader[] = [
  { name: "Date", property: "date" },
  { name: "Cost Per Gallon", property: "costPerGallon" },
  { name: "Number of Gallons", property: "numberOfGallons" },
  { name: "Mileage", property: "mileage" }
];

@Component({
  selector: 'app-gas-logs-splash',
  templateUrl: './gas-logs-splash.component.html',
  styleUrls: ['./gas-logs-splash.component.scss']
})
export class GasLogsSplashComponent implements OnInit {
  private gasLogTableHeaders: TableHeader[];
  private logs: GasLog[];
  private dataLoaded: boolean = false;

  constructor(
    private gasLogService: GasLogService,
    private router: Router,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.gasLogTableHeaders = GAS_LOG_TABLE_HEADERS;
    this.gasLogService.getLogs().subscribe(logs => {
      this.logs = logs;
      this.dataLoaded = true;
    });
  }

  onClickLog(log: GasLog) {
    this.router.navigate(['/logs/gas/edit', log.id]);
  }

}
