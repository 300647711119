import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { Part } from 'src/app/models/Part'

export class PartLog {
    id: string;
    purchasedDate: any;
    purchasedFrom: string;
    cost: string;
    notes: string;
    parts: Part[] = [];
    files: UploadedFile[] = [];
}