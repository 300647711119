import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagInputModule } from 'ngx-chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedFormsModule as TreadSharedFormsModule } from 'src/app/forms-shared/shared-forms.module';
import { ComponentsModule } from 'src/app/components/components.module'
import { UploadFilesComponent } from "./upload-files/upload-files.component";
import { ShareVehicleComponent } from './share-vehicle/share-vehicle.component';
import { AddServiceComponent } from './add-service/add-service.component';
import { UpdateMileageComponent } from './update-mileage/update-mileage.component';
import { AddPartsComponent } from './add-parts/add-parts.component';
import { AddReminderComponent } from './add-reminder/add-reminder.component';
import { AddCustomServicesComponent } from './add-custom-services/add-custom-services.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    TagInputModule,
    FormsModule,
    TreadSharedFormsModule
  ],
  declarations: [
    UploadFilesComponent,
    ShareVehicleComponent,
    AddServiceComponent,
    UpdateMileageComponent,
    AddPartsComponent,
    AddReminderComponent,
    AddCustomServicesComponent
  ],
  exports: [
    UploadFilesComponent,
    ShareVehicleComponent,
    AddServiceComponent,
    UpdateMileageComponent,
    AddPartsComponent,
    AddReminderComponent,
    AddCustomServicesComponent
  ]
})
export class ModalsModule { }
