import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { UploadedFile } from 'src/app/models/files/UploadedFile';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private uploadUrl = environment.apiUrl + '/files/upload';
  private downloadUrl = environment.apiUrl + '/files/download';
  private deleteUrl = environment.apiUrl + '/files/delete';

  constructor(private http: HttpClient) { }

  uploadFile(file: File): Observable<UploadedFile> {
    const formData = new FormData();
    formData.append('files', file);
    return this.http.post<any>(this.uploadUrl, formData)
    .pipe(
      map(files => {
        return files[0];
      })
    );
  }

  downloadFile(file: UploadedFile) {
    const body = {
      id: file.id
    }
    this.http.post(this.downloadUrl, body, { responseType: 'blob' }).subscribe(
        (response: any) => {
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([response], {type: "application/pdf"}));
            downloadLink.setAttribute('download', file.originalName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.parentNode.removeChild(downloadLink);
        }
    )
  }

  deleteFile(fileId: string) {
    const body = {
      id: fileId
    }
    return this.http.post<any>(this.deleteUrl, body, httpOptions);
  }
}
