import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { loadStripe, Stripe, StripeEmbeddedCheckout } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

import { QueryParams } from 'src/app/models/QueryParams';
import { SubscriptionPlan } from 'src/app/models/SubscriptionPlan';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {
  private stripe: Stripe | null = null;
  private checkout: StripeEmbeddedCheckout;
  private subscriptionPlan: SubscriptionPlan = SubscriptionPlan.PERSONAL;
  private queryParams: QueryParams = new QueryParams();

  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.stripe = await loadStripe(environment.stripeKey);
    this.queryParams.subscriptionPlan = this.route.snapshot.queryParams['subscriptionPlan'];
    if (this.queryParams.subscriptionPlan) {
      this.subscriptionPlan = this.getPlan(this.queryParams.subscriptionPlan);
    }
    if (this.subscriptionPlan === SubscriptionPlan.FOUNDERS_SPECIAL) {
      this.subscriptionService.subscribe(this.subscriptionPlan).subscribe(() => {
        this.router.navigate(['/complete-checkout']);
      });
    } else {
      if (!this.checkout) {
        this.subscriptionService.checkout(this.subscriptionPlan).subscribe(async (checkoutSession) => {
          const sessionId = checkoutSession.sessionId;
          const checkout = await this.stripe?.initEmbeddedCheckout({
            clientSecret: sessionId
          });
  
          if (checkout) {
            checkout.mount('#checkout');
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.unmountCheckout();
  }

  unmountCheckout() {
    if (this.checkout) {
      this.checkout.unmount();
      this.checkout = null;
    }
  }

  getPlan(plan: string): SubscriptionPlan {
    switch (plan) {
      case 'PERSONAL':
        return SubscriptionPlan.PERSONAL;
      case 'FAMILY':
        return SubscriptionPlan.FAMILY;
      case 'FLEET':
        return SubscriptionPlan.FLEET;
      case 'FOUNDERS_SPECIAL':
        return SubscriptionPlan.FOUNDERS_SPECIAL;
    }
  }

}