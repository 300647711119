import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-logs',
  templateUrl: './registration-logs.component.html',
  styleUrls: ['./registration-logs.component.scss']
})
export class RegistrationLogsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
