import { Component, OnInit } from '@angular/core';

import { PartLogService } from  'src/app/services/part-log.service'
import { PartLog } from 'src/app/models/PartLog'
import { TableHeader } from 'src/app/models/tables/TableHeader';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';

const PART_LOG_TABLE_HEADERS: TableHeader[] = [
  { name: "Date Purchased", property: "purchasedDate" },
  { name: "Parts", property: "partTypes" },
  { name: "Purchased From", property: "purchasedFrom" }
];

@Component({
  selector: 'app-part-logs-splash',
  templateUrl: './part-logs-splash.component.html',
  styleUrls: ['./part-logs-splash.component.scss']
})
export class PartLogsSplashComponent implements OnInit {
  private partLogTableHeaders: TableHeader[];
  private logs: PartLog[];
  private dataLoaded: boolean = false;

  constructor(
    private partLogService: PartLogService,
    private router: Router,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.partLogTableHeaders = PART_LOG_TABLE_HEADERS;
    this.partLogService.getLogs().subscribe(partLogs => {
      const logs: any = partLogs;
      this.logs = logs.map(log => {
        log.partTypes = log.parts.map(part => part.type);
        return log;
      });
      this.dataLoaded = true;
    });
  }

  onClickLog(log: PartLog) {
    this.router.navigate(['/logs/parts/edit', log.id]);
  }

}
