import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Alert } from '../models/Alert';
import { AlertDueInPriority } from '../models/AlertDueInPriority';
import StringUtils from '../utils/string-utils';
import { UserVehicleService } from './user-vehicle.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

const OVERDUE: string  = "Overdue";

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private getUrl = environment.apiUrl + '/user/vehicle/alerts/get';
  private dismissUrl = environment.apiUrl + '/user/vehicle/alerts/dismiss';

  constructor(
    private http: HttpClient,
    private userVehicleService: UserVehicleService
  ) { }

  getByUserVehicle(): Observable<Alert[]> {
    const body = {
      userVehicleId: this.userVehicleService.getCurrentVehicle().id
    }
    return this.http.post<Alert[]>(this.getUrl, body, httpOptions);
  }

  dismiss(alertId: string): Observable<any> {
    const body = {
      alertId: alertId
    }
    return this.http.post<any>(this.dismissUrl, body, httpOptions);
  }

  getDueInText(alert: Alert) {
    if (alert.dueInPriority === AlertDueInPriority.MILES) {
      return this.getDueInMileageText(alert);
    } else if (alert.dueInPriority === AlertDueInPriority.TIME) {
      return this.getDueInTimeText(alert);
    }
  }

  getDueInMileageText(alert: Alert) {
    const dueInMiles = alert.dueInMiles;
    if (dueInMiles <= 0) {
      return OVERDUE;
    }
    return "Due in " + StringUtils.formatNumber(dueInMiles) + " miles";
  }

  getDueInTimeText(alert: Alert) {
    const dueInTime = alert.dueInTime;
    const temporalUnit = alert.temporalUnit;
    if (dueInTime <= 0) {
      return OVERDUE;
    } else if (dueInTime === 1) {
      return "Due in " + dueInTime + " " + temporalUnit.toString().toLowerCase();
    } else if (dueInTime > 1) {
      return "Due in " + dueInTime + " " + temporalUnit.toString().toLowerCase() + "s";
    }
  }
}
