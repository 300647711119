import { EventEmitter, Injectable, Output } from '@angular/core';

import { User } from 'src/app/models/User';

@Injectable({
  providedIn: 'root'
})
export class AuthSharedService {
  @Output() setLoggedInUserEventEmitter: EventEmitter<User> = new EventEmitter();

  constructor() { }

  setLoggedInUser(user: User) {
    this.setLoggedInUserEventEmitter.emit(user);
  }

  setLoggedInUserEvent(): EventEmitter<User> {
    return this.setLoggedInUserEventEmitter;
  }
}
