import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { FileService } from 'src/app/services/file.service';

const PDF_FILE_EXTENSIONS = ["pdf"]
const WORD_DOCUMENT_FILE_EXTENSIONS = ["docx"]
const POWERPOINT_FILE_EXTENSIONS = ["ppt"];
const EXCEL_FILE_EXTENSIONS = ["xlsx"];
const IMAGE_FILE_EXTENSIONS = ["jpg"];
const VIDEO_FILE_EXTENSIONS = ["mp4"];
const FILE_EXTENSIONS = [].concat(
    PDF_FILE_EXTENSIONS,
    WORD_DOCUMENT_FILE_EXTENSIONS, 
    POWERPOINT_FILE_EXTENSIONS,
    EXCEL_FILE_EXTENSIONS,
    IMAGE_FILE_EXTENSIONS,
    VIDEO_FILE_EXTENSIONS
);

@Component({
  selector: 'app-file-preview-list',
  templateUrl: './file-preview-list.component.html',
  styleUrls: ['./file-preview-list.component.scss']
})
export class FilePreviewListComponent implements OnInit {
  @Input() files: UploadedFile[] = [];
  @Input() maxFiles: number = 10;
  @Input() isDownloadEnabled: boolean = true;
  @Input() isDeleteEnabled: boolean = true;
  @Output() deleteFileEvent = new EventEmitter<UploadedFile>();

  constructor(private fileService: FileService) { }

  ngOnInit() { }

  onClickDownload(file: UploadedFile) {
    this.fileService.downloadFile(file);
  }

  onClickDelete(file: UploadedFile) {
    this.deleteFileEvent.emit(file);
  }

  isPdfFile(file: UploadedFile) {
    return PDF_FILE_EXTENSIONS.includes(this.getFileExtension(file.originalName));
  }

  isWordDocument(file: UploadedFile) {
    return WORD_DOCUMENT_FILE_EXTENSIONS.includes(this.getFileExtension(file.originalName));
  }

  isPowerpointFile(file: UploadedFile) {
    return POWERPOINT_FILE_EXTENSIONS.includes(this.getFileExtension(file.originalName));
  }

  isExcelFile(file: UploadedFile) {
    return EXCEL_FILE_EXTENSIONS.includes(this.getFileExtension(file.originalName));
  }

  isImageFile(file: UploadedFile) {
    return IMAGE_FILE_EXTENSIONS.includes(this.getFileExtension(file.originalName));
  }

  isVideoFile(file: UploadedFile) {
    return VIDEO_FILE_EXTENSIONS.includes(this.getFileExtension(file.originalName));
  }

  isUnknownFile(file: UploadedFile) {
    return !FILE_EXTENSIONS.includes(this.getFileExtension(file.originalName));
  }

  getFileExtension(filename: string) {
    return filename.split('.').pop();
  }

  getReadableFileSize(size: number) {
    var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = 0;
    if (size < 1024) {
      return size + ' ' + units[i];
    }
    while (size >= 1024) {
      size /= 1024;
      ++i;
    }
    return size.toFixed(1) + ' ' + units[i];
  }

}
