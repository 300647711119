import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Part } from 'src/app/models/Part';

enum View {
  NONE,
  ADD,
  SEARCH
}

@Component({
  selector: 'app-add-parts-modal',
  templateUrl: './add-parts.component.html',
  styleUrls: ['./add-parts.component.scss']
})
export class AddPartsComponent implements OnInit {
  @Input() searchForExistingPartsEnabled: boolean = true;
  @Input() part: Part = new Part;
  @Input() viewOnly: boolean = false;
  @Input() partsToExcludeFromSearch: Part[] = [];
  @Output() closeEvent = new EventEmitter<any>();
  @Output() doneEvent = new EventEmitter<Part>();
  @Output() doneEditingEvent = new EventEmitter<Part>();
  @Output() doneSearchingForExistingPartsEvent = new EventEmitter<Part[]>();
  private View = View;
  private view: View = View.NONE;

  constructor() { }

  ngOnInit() {
    if (!this.searchForExistingPartsEnabled || this.part.type) {
      this.view = View.ADD;
    }
  }

  onClickClose() {
    this.closeEvent.emit();
  }
  
  onClickAddNewPart() {
    this.view = View.ADD;
  }

  onClickSearchForExistingParts() {
    this.view = View.SEARCH;
  }

  onDoneAddingNewPart(part: Part) {
    this.doneEvent.emit(part);
  }

  onDoneEditingPart(part: Part) {
    this.doneEditingEvent.emit(part);
  }

  onDoneSearchingForExistingParts(parts: Part[]) {
    this.doneSearchingForExistingPartsEvent.emit(parts);
  }

}
