import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TagInputComponent } from 'ngx-chips';
import { Observable, of, throwError } from 'rxjs';

import { SharedUserBuilder } from 'src/app/models/builders/SharedUserBuilder';
import { Tag } from 'src/app/models/components/Tag';
import { SharedUser } from 'src/app/models/SharedUser';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import StringUtils from 'src/app/utils/string-utils';

const LOGGED_IN_USER_INDICATOR: string = "(You)";
const OWNER_INDICATOR: string = "(owner)";
const SPACE: string = " ";

@Component({
  selector: 'app-share-vehicle',
  templateUrl: './share-vehicle.component.html',
  styleUrls: ['./share-vehicle.component.scss']
})
export class ShareVehicleComponent implements OnInit {
  @Input() sharedUsers: SharedUser[] = [];
  @Input() readonly: boolean = false;
  @Output() sharedUsersUpdatedEvent = new EventEmitter<SharedUser[]>();
  @ViewChild('sharedUsersTagInput') sharedUsersTagInput: TagInputComponent;
  private tags: Tag[] = [];
  private loggedInUser: User;
  private validators = [ this.emailValidator.bind(this) ];
  private validateTagFunction = this.validateTag.bind(this);
  private errorMessages = {
    'invalidEmail': 'Please use a valid email address'
  };
  private addTagFirstAttemptFailed = false;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loggedInUser = this.authService.getLoggedInUser();
    this.buildTags();
  }

  buildTags() {
    this.buildTagsForLoggedInUserAndOwner();
    this.buildTagsForOtherUsers();
  }

  buildTagsForLoggedInUserAndOwner() {
    const owner: SharedUser = this.sharedUsers.find(user => user.owner);
    if (owner.id === this.loggedInUser.id) {
      this.tags.push(new Tag(
          this.loggedInUser.name + SPACE + OWNER_INDICATOR + SPACE + LOGGED_IN_USER_INDICATOR,
          this.loggedInUser.id,
          true
        ));
    } else {
      this.tags.push(new Tag(
        this.loggedInUser.name + SPACE + LOGGED_IN_USER_INDICATOR,
        this.loggedInUser.id,
        true
      ));
      this.tags.push(new Tag(
        owner.name + SPACE + OWNER_INDICATOR,
        owner.id,
        true
      ));
    }
  }

  buildTagsForOtherUsers() {
    const otherUsers = this.sharedUsers
      .filter(user => user.id !== this.loggedInUser.id)
      .filter(user => !user.owner)
    for (let user of otherUsers) {
      this.tags.push(new Tag(user.name || user.email, user.id || user.email, false));
    }
  }

  emailValidator(control: FormControl) {   
    if (this.addTagFirstAttemptFailed && !StringUtils.isEmail(control.value)) {
      return { "invalidEmail": true };
    }
    return null;
  }

  validateTag(tag: string): Observable<string> {
    if (!StringUtils.isEmail(tag)) {
      if (!this.addTagFirstAttemptFailed) {
        this.addTagFirstAttemptFailed = true;
        this.sharedUsersTagInput.setInputValue(tag);
      }
      return throwError(this.errorMessages['invalidEmail']);
    }
    this.addTagFirstAttemptFailed = false;
    return of(tag);
  }

  onAddTag(tag: Tag) {
    const email = tag.value;
    this.sharedUsers.push(
      new SharedUserBuilder()
        .email(email)
        .build()
    );
    this.sharedUsersUpdatedEvent.emit(this.sharedUsers);
  }

  onRemoveTag(tag: Tag) {
    this.sharedUsers = this.sharedUsers.filter(user => 
      (user.id !== tag.value) && (user.email !== tag.value)
    );
    this.sharedUsersUpdatedEvent.emit(this.sharedUsers);
  }
}
