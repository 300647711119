import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Part } from 'src/app/models/Part';

@Component({
  selector: 'app-added-parts-table',
  templateUrl: './added-parts-table.component.html',
  styleUrls: ['./added-parts-table.component.scss']
})
export class AddedPartsTableComponent implements OnInit {
  @Input() parts: Part[] = [];
  @Input() isViewEnabled: boolean = true;
  @Input() isEditEnabled: boolean = true;
  @Input() isDeleteEnabled: boolean = true;
  @Output() viewPartEvent = new EventEmitter<Part>();
  @Output() editPartEvent = new EventEmitter<Part>();
  @Output() deletePartEvent = new EventEmitter<Part>();
  private tableHeaders: string[] = ["Part", ""];

  constructor() { }

  ngOnInit() {}

}
