import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { SharedUser } from 'src/app/models/SharedUser';
import { User } from 'src/app/models/User';
import { UserVehicle } from 'src/app/models/UserVehicle';
import { VehicleSettings } from 'src/app/models/VehicleSettings';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserVehicleService } from 'src/app/services/user-vehicle.service';
import Utils from 'src/app/utils/utils';

const DEFAULT_VEHICLE_DROPDOWN_OPTION = "Select a vehicle";

@Component({
  selector: 'app-vehicle-settings',
  templateUrl: './vehicle-settings.component.html',
  styleUrls: ['./vehicle-settings.component.scss']
})
export class VehicleSettingsComponent implements OnInit {
  @ViewChild('vehicleSettingsForm') vehicleSettingsForm: NgForm;
  private formDisabled: boolean = false;
  private shareVehicleModal: BsModalRef;
  private vehicleDropdownValue: string = DEFAULT_VEHICLE_DROPDOWN_OPTION;
  private userVehicles: UserVehicle[] = [];
  private vehicleSettings: VehicleSettings = new VehicleSettings();
  private loggedInUser: User;
  private loggedInUserIsOwner: boolean = false;

  constructor(
    private userVehicleService: UserVehicleService,
    private authService: AuthService,
    private modalService: BsModalService,
    private alertService: AlertService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const userVehicles = this.userVehicleService.getUserVehiclesFromLocalStorage();
    if (userVehicles) {
      this.userVehicles = userVehicles;
      this.getCurrentVehicleAndPopulateForm();
    } else {
      this.userVehicleService.getUserVehicles().subscribe(userVehicles => {
        this.userVehicles = userVehicles;
        this.getCurrentVehicleAndPopulateForm();
      });
    }
    this.loggedInUser = this.authService.getLoggedInUser();
  }

  getCurrentVehicleAndPopulateForm() {
    const currentVehicle = this.userVehicleService.getCurrentVehicle();
    if (currentVehicle) {
      this.getGarageSettingsAndPopulateForm(currentVehicle.id);
    } else {
      this.formDisabled = true;
    }
  }

  getGarageSettingsAndPopulateForm(userVehicleId: string) {
    this.vehicleDropdownValue = userVehicleId;
    this.getGarageSettings(userVehicleId);
  }

  getGarageSettings(userVehicleId: string) {
    this.userVehicleService.getSettings(userVehicleId).subscribe(settings => {
      this.vehicleSettings = settings;
      this.determineIfLoggedInUserIsOwner();
      this.formDisabled = false;
    });
  }

  onChangeSelectedVehicle() {
    if (this.vehicleDropdownValue === DEFAULT_VEHICLE_DROPDOWN_OPTION) {
      this.formDisabled = true;
      return;
    }
    this.getGarageSettingsAndPopulateForm(this.vehicleDropdownValue);
  }

  onClickSave() {
    this.userVehicleService.updateNickname(this.vehicleSettings.id,
      this.vehicleSettings.nickname).subscribe(() => {
        this.toastService.showSuccess('Saved successfully');
        this.userVehicleService.getUserVehicles().subscribe(userVehicles => {
          this.userVehicles = userVehicles;
          const currentVehicle = Utils.getByIdFromArray(userVehicles, this.vehicleSettings.id);
          this.userVehicleService.setCurrentVehicle(currentVehicle);
          this.getGarageSettingsAndPopulateForm(currentVehicle.id);
        });
      });
  }

  onClickShareWithOtherDrivers(modal: TemplateRef<any>) {
    this.shareVehicleModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseShareVehicleModal() {
    this.shareVehicleModal.hide();
  }

  onDoneSharingVehicle(sharedUsers: SharedUser[]) {
    this.userVehicleService.share(this.vehicleSettings.id, sharedUsers).subscribe(() => {
      this.shareVehicleModal.hide();
      this.getGarageSettingsAndPopulateForm(this.vehicleSettings.id);
    });
  }

  onClickDeleteVehicle() {
    this.alertService.showAreYouSureYouWantToDelete()
      .then((result) => {
        if (result.value) {
          this.userVehicleService.delete(this.vehicleSettings.id).subscribe(() =>
            this.alertService.showDeleteConfirmation("This vehicle has been deleted.")
            .then((result) => {
              if (result.value) {
                this.router.navigate(['/garage']);
              }
            })
          );
        }
      });
  }

  determineIfLoggedInUserIsOwner() {
    const owner: SharedUser = this.vehicleSettings.sharedUsers.find(user => user.owner);
    if (this.vehicleSettings && (owner.id === this.loggedInUser.id)) {
      this.loggedInUserIsOwner = true;
    } else {
      this.loggedInUserIsOwner = false;
    }
  }

  getVehicleDropdownDefaultValue() {
    return DEFAULT_VEHICLE_DROPDOWN_OPTION;
  }

}
