import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import StringUtils from 'src/app/utils/string-utils';

@Component({
  selector: 'app-update-mileage-modal',
  templateUrl: './update-mileage.component.html',
  styleUrls: ['./update-mileage.component.scss']
})
export class UpdateMileageComponent implements OnInit {
  @Input() mileage: number;
  @Output() closeEvent = new EventEmitter<any>();
  @Output() doneEvent = new EventEmitter<number>();
  private newMileage: number;
  private valid: boolean = true;
  private errorMessage: string = "";

  constructor() { }

  ngOnInit() {
  }

  onClickClose() {
    this.closeEvent.emit();
  }

  onClickCancel() {
    this.closeEvent.emit();
  }

  onClickDone() {
    this.validate();
    if (!this.valid) {
      return;
    }
    this.doneEvent.emit(this.newMileage);
  }

  validate() {
    this.reset();
    if (this.newMileage <= this.mileage) {
      this.valid = false;
      this.errorMessage = "Must be greater than the old mileage";
    }
  }

  reset() {
    this.valid = true;
    this.errorMessage = "";
  }

  formatNumber(number: number) {
    return StringUtils.formatNumber(number);
  }
 
}
