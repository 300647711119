import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarSharedService {
  @Output() selectMenuItemEventEmitter: EventEmitter<any> = new EventEmitter();

  constructor() { }

  selectMenuItem() {
    this.selectMenuItemEventEmitter.emit();
  }

  selectMenuItemEvent(): EventEmitter<any> {
    return this.selectMenuItemEventEmitter;
  }
}
