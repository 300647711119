import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ServiceLog } from 'src/app/models/ServiceLog';
import { ServiceLogService } from 'src/app/services/service-log.service';
import { PartService } from 'src/app/services/part.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-add-service-log',
  templateUrl: './add-service-log.component.html',
  styleUrls: ['./add-service-log.component.scss']
})
export class AddServiceLogComponent implements OnInit {
  private log: ServiceLog = new ServiceLog();

  constructor(
    private serviceLogService: ServiceLogService,
    private partService: PartService,
    private toastService: ToastService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onClickClose() {
    this.onDone();
  }

  onDoneAddingLog(log: ServiceLog) {
    if (!log) {
      this.onDone();
      return;
    }
    this.addLog(log);
  }

  addLog(log: ServiceLog) {
    this.serviceLogService.addLog(log).subscribe(
      () => this.onDone(),
      (err) => this.toastService.showSomethingWentWrong()
    );
  }

  onDone() {
    this.router.navigate(['/logs/services']);
  }

}
