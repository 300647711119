import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { Part } from 'src/app/models/Part'

export class ServiceLog {
    id: string;
    date: any;
    services: string[] = [];
    servicedAt: string;
    servicedBy: string;
    cost: string;
    notes: string;
    parts: Part[] = [];
    mileage: number;
    files: UploadedFile[] = [];
}