import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { RegistrationLog } from 'src/app/models/RegistrationLog';
import { AlertService } from 'src/app/services/alert.service';
import { RegistrationLogService } from 'src/app/services/registration-log.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-edit-registration-log',
  templateUrl: './edit-registration-log.component.html',
  styleUrls: ['./edit-registration-log.component.scss']
})
export class EditRegistrationLogComponent implements OnInit {
  private log: RegistrationLog;
  private dataLoaded: boolean = false;
  private viewOnly: boolean = true;
  private editMode: boolean = false;

  constructor(
    private registrationLogService: RegistrationLogService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.registrationLogService.getLog(params.id).subscribe((log: RegistrationLog) => {
          this.log = log;
          this.dataLoaded = true;
        });
      }
    )
  }

  onClickEdit() {
    this.toggleEditMode();
  }

  onClickDelete() {
    this.alertService.showAreYouSureYouWantToDelete()
      .then((result) => {
        if (result.value) {
          this.registrationLogService.deleteLog(this.log.id).subscribe(() => {
            this.alertService.showDeleteConfirmation("This log has been deleted.");
            this.router.navigate(['/logs/registration']);
          });
        }
      });
  }

  onClickClose() {
    this.onDone();
  }

  onDoneUpdatingLog(log: RegistrationLog) {
    if (this.viewOnly) {
      this.onDone();
      return;
    }
    this.registrationLogService.updateLog(log).subscribe(() => {
      this.onDone();
      this.toastService.showSuccess("Updated successfully");
    });
  }

  onDone() {
    this.router.navigate(['/logs/registration']);
  }

  toggleEditMode() {
    this.viewOnly = false;
    this.editMode = true;
  }

}
