import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SubscriptionStatus } from 'src/app/models/SubscriptionStatus';
import { AlertService } from 'src/app/services/alert.service';

import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-complete-checkout',
  templateUrl: './complete-checkout.component.html',
  styleUrls: ['./complete-checkout.component.scss']
})
export class CompleteCheckoutComponent implements OnInit {
  private POLL_INTERVAL = 2000; // 2 seconds
  private TIMEOUT = 20000; // 20 seconds

  constructor(
    private router: Router,
    private alertService: AlertService,
    private userService: UserService,
    private authService: AuthService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService 
  ) { }

  ngOnInit(): void {
    const startTime = Date.now();
    this.pollSubscriptionStatus(startTime);
  }

  pollSubscriptionStatus(startTime: number): void {
    this.spinner.show();
    this.userService.getSubscription().pipe(
      map(subscription => {
        if (subscription.status === SubscriptionStatus.ACTIVE) {
          const loggedInUser = this.authService.getLoggedInUser();
          loggedInUser.subscription = subscription;
          this.authService.setLoggedInUser(loggedInUser);

          this.alertService.showSuccess("Welcome to Tread!", "Thank you for subscribing!<br><br> Keep an eye on your inbox for updates to get the most out of your subscription. You can manage your subscription anytime in your account settings.", "Go to garage");
          this.router.navigate(['/garage']);
          this.spinner.hide();
        } else {
          if (Date.now() - startTime < this.TIMEOUT) {
            setTimeout(() => this.pollSubscriptionStatus(startTime), this.POLL_INTERVAL);
          } else {
            this.somethingWentWrong();
          }
        }
      }),
      catchError(err => {
        this.somethingWentWrong();
        return of(false);
      })
    ).subscribe();
  }

  somethingWentWrong() {
    this.spinner.hide();
    this.toastService.showSomethingWentWrong();
    // TODO redirect
  }
}