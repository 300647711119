import { EventEmitter, Injectable, Output } from '@angular/core';

import { UserVehicle } from 'src/app/models/UserVehicle';

@Injectable({
  providedIn: 'root'
})
export class UserVehicleSharedService {
  @Output() setCurrentVehicleEventEmitter: EventEmitter<UserVehicle> = new EventEmitter();
  @Output() setUserVehiclesEventEmitter: EventEmitter<UserVehicle[]> = new EventEmitter();

  constructor() { }

  setCurrentVehicle(userVehicle: UserVehicle) {
    this.setCurrentVehicleEventEmitter.emit(userVehicle);
  }

  setCurrentVehicleEvent(): EventEmitter<UserVehicle> {
    return this.setCurrentVehicleEventEmitter;
  }

  setUserVehicles(userVehicles: UserVehicle[]) {
    this.setUserVehiclesEventEmitter.emit(userVehicles);
  }

  setUserVehiclesEvent(): EventEmitter<UserVehicle[]> {
    return this.setUserVehiclesEventEmitter;
  }
}
