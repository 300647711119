import { SharedUser } from 'src/app/models/SharedUser';

export class SharedUserBuilder {
    _owner: boolean;
    _id: string;
    _name: string;
    _email: string;

    owner(owner: boolean) {
        this._owner = owner;
        return this;
    }

    id(id: string) {
        this._id = id;
        return this;
    }

    name(name: string) {
        this._name = name;
        return this;
    }

    email(email: string) {
        this._email = email;
        return this;
    }

    build() {
        return new SharedUser(this);
    }

}