import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Selectr from 'mobius1-selectr';

import { Part } from 'src/app/models/Part';
import { PartService } from 'src/app/services/part.service';

@Component({
  selector: 'app-parts-search',
  templateUrl: './parts-search.component.html',
  styleUrls: ['./parts-search.component.scss']
})
export class PartsSearchComponent implements OnInit {
  @Input() partsToExclude: Part[] = [];
  @Output() doneEvent = new EventEmitter<Part[]>();
  private partsSelector: Selectr;
  private parts: Part[];
  private selectedParts: string[] = [];

  constructor(private partService: PartService) { }

  ngOnInit() {
    this.partService.getParts().subscribe(parts => {
      this.parts = parts.filter(part => {
        const partsToExclude = this.partsToExclude
          .filter(part => part.id)
          .map(part => part.id);
        return !partsToExclude.includes(part.id);
      })
      this.initPartsSelector();
    });
  }

  initPartsSelector() {
    this.partsSelector = new Selectr(
      document.getElementById("partsSelector") as HTMLSelectElement, 
      {
        multiple: true,
        maxSelections: 10,
        placeholder: 'Select parts...',
        closeOnScroll: true,
        messages: {
          maxSelections: "A maximum of {max} parts can be selected."
        },
        data: this.buildDataForPartsSelector()
      }
    );
    this.setHandlersForPartsSelector();
  }

  setHandlersForPartsSelector() {
    this.partsSelector.on('selectr.select', (selector) => {
      this.selectedParts.push(selector.value);
    });

    this.partsSelector.on('selectr.deselect', (selector) => {
      this.selectedParts = this.selectedParts.filter(p => p !== selector.value);
    });
  }

  onClickDone() {
    const parts = this.parts.filter(part => this.selectedParts.includes(part.id));
    this.doneEvent.emit(parts);
  }

  buildDataForPartsSelector() {
    let data = [];
    for (let part of this.parts) {
      let text = part.type;
      if (part.name && !part.number) {
        text += " (" + part.name + ")";
      } else if (part.name && part.number) {
        text += " (" + part.name + " - " + part.number + ")";
      }
      data.push({
        text: text,
        value: part.id
      });
    }
    return data;
  }

}
