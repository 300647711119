import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, TemplateRef, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import Quill from "quill";

import { IncidentLog } from 'src/app/models/IncidentLog';
import StringUtils from 'src/app/utils/string-utils';
import DateUtils from 'src/app/utils/date-utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { UploadedFile } from 'src/app/models/files/UploadedFile';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-add-incident-log-form',
  templateUrl: './add-incident-log.component.html',
  styleUrls: ['./add-incident-log.component.scss']
})
export class AddIncidentLogComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() log: IncidentLog = new IncidentLog();
  @Input() viewOnly: boolean = false;
  @Input() editMode: boolean = false;
  @Output() doneEvent = new EventEmitter<IncidentLog>();
  @ViewChild('addIncidentLogForm') form: NgForm;
  private descriptionTextEditor: Quill;
  private uploadFilesModal: BsModalRef;

  constructor(
    private fileService: FileService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.initDescriptionTextEditor();
  }

  ngAfterViewInit() {
    if (this.log.date) {
      this.log.date = StringUtils.formatDateForInput(this.log.date);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const viewOnlyChange = changes['viewOnly'];
    if (viewOnlyChange) {
      if (viewOnlyChange.currentValue) {
        this.disableNotesTextEditor();
      } else {
        this.enableNotesTextEditor();
      }
    }
  }

  initDescriptionTextEditor() {
    this.descriptionTextEditor = new Quill("#descriptionTextEditor", {
      modules: {
        toolbar: [
          ["bold", "italic"],
          [
            {
              list: "ordered"
            },
            {
              list: "bullet"
            }
          ]
        ]
      },
      theme: "snow"
    });
    const delta = this.descriptionTextEditor.clipboard.convert(this.log.description);
    this.descriptionTextEditor.setContents(delta);
    this.setHandlersForDescriptionTextEditor();
    if (this.viewOnly) {
      this.descriptionTextEditor.disable();
    }
  }

  setHandlersForDescriptionTextEditor() {
    this.descriptionTextEditor.on('text-change', (delta, oldDelta, source) => {
      const textLimit = 2500;
      if (this.descriptionTextEditor.getLength() > textLimit) {
        this.descriptionTextEditor.deleteText(textLimit, this.descriptionTextEditor.getLength());
      }
      this.log.description = this.descriptionTextEditor.root.innerHTML;
    });
  }

  enableNotesTextEditor() {
    this.descriptionTextEditor.enable();
  }

  disableNotesTextEditor() {
    this.descriptionTextEditor.disable();
  }

  onChangeDate(element) {
    if (!DateUtils.isValid(this.log.date) || !DateUtils.isInPast(moment(this.log.date))) {
      element.target.value = null;
    }
  }

  onClickTodayLink() {
    this.form.controls['date'].setValue(moment().format("YYYY-MM-DD"));
  }

  onClickAddFilesLink(modal: TemplateRef<any>) {
    this.uploadFilesModal = this.modalService.show(modal, {
      keyboard: true,
      class: "modal-dialog-centered"
    });
  }

  onClickCloseUploadFilesModal() {
    this.closeUploadFilesModal();
  }

  onDoneUploadingFiles(files: UploadedFile[]) {
    this.closeUploadFilesModal();
    if (files && files.length > 0) {
      this.log.files.push(...files);
    }
  }

  closeUploadFilesModal() {
    this.uploadFilesModal.hide();
  }

  onClickDeleteFile(file: UploadedFile) {
    this.log.files = this.log.files.filter(f => f.id !== file.id);
    if (!this.editMode) {
      this.fileService.deleteFile(file.id).subscribe(() => {});
    }
  }

  onClickDone() {
    this.onFormSubmit();
    if (!this.isFormValid()) {
      return;
    }
    this.doneEvent.emit(this.log);
  }

  onFormSubmit() {
    this.form.control.markAllAsTouched();
  }

  isFormValid() {
    return this.form.valid;
  }

}
